import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, unref as _unref, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { id: "answerResultPage" }
const _hoisted_2 = { style: {
                  display: 'flex',
                  alignItems: 'center',
                } }

import API from "@/axios/api";
import { getUserAnswerVoByIdUsingGet } from "@/axios/api/userAnswerController";
import { ref, watchEffect, computed } from "vue";
import message from "@arco-design/web-vue/es/message";
import { dayjs } from "@arco-design/web-vue/es/_utils/date";
import { APP_TYPE_MAP, APP_SCORING_STRATEGY_MAP } from "@/constant/appEnum";
import { useLoginUserStore } from "@/store/userStore";
import { listQuestionVoByPageUsingPost } from "@/axios/api/questionController";
interface Props {
  id: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AnswerResultPage',
  props: {
    id: { default: () => 0 }
  },
  setup(__props: any) {

const props = __props;
const data = ref<API.UserAnswerVO>();
const loginUserStore = useLoginUserStore();
let loginUserId = loginUserStore.loginUser?.id;
// 是否為本人創建
const isMy = computed(() => {
  return loginUserId && loginUserId == data.value?.userId;
});
// 我的答案的遍历
const questionContent = ref<API.QuestionContentDTO[]>([]);
const loadQuestionContent = async () => {
  // 获取题目
  const res = await listQuestionVoByPageUsingPost({
    appId: data.value?.appId,
    current: 1,
    pageSize: 1,
    sortField: "createTime",
    sortOrder: "descend",
  });
  if (res.data.code === 0 && res.data.data?.records) {
    questionContent.value = res.data.data?.records[0]?.questionContent || [];
  } else {
    message.error("获取题目失败：" + res.data.message);
  }
};

const loadData = async () => {
  if (!props.id) return;
  const res = await getUserAnswerVoByIdUsingGet({ id: props.id });
  if (res.data.code === 0) {
    data.value = res.data.data;
  } else {
    message.error("获取数据失败：" + res.data.message);
  }
  loadQuestionContent();
};
watchEffect(() => {
  loadData();
});

return (_ctx: any,_cache: any) => {
  const _component_a_collapse_item = _resolveComponent("a-collapse-item")!
  const _component_a_collapse = _resolveComponent("a-collapse")!
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_row, { style: {"margin-bottom":"16px"} }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, {
              flex: "auto",
              class: "content-wrapper"
            }, {
              default: _withCtx(() => [
                _createElementVNode("h2", null, _toDisplayString(data.value?.resultName), 1),
                _createElementVNode("p", null, "结果描述：" + _toDisplayString(data.value?.resultDesc), 1),
                _createElementVNode("p", null, "结果 ID: " + _toDisplayString(data.value?.resultId), 1),
                _createElementVNode("p", null, "应用 ID: " + _toDisplayString(data.value?.appId), 1),
                _createElementVNode("p", null, "结果得分：" + _toDisplayString(data.value?.resultScore), 1),
                _createElementVNode("p", null, "我的答案：" + _toDisplayString(data.value?.choices), 1),
                _createVNode(_component_a_collapse, {
                  "default-active-key": ['1'],
                  bordered: false
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_collapse_item, { header: "答案回顾" }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(questionContent.value, (question, index) => {
                          return (_openBlock(), _createBlock(_component_a_collapse_item, {
                            header: 
                  question.title + '。 你的答案：' + data.value?.choices?.[index]
                ,
                            key: index
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(question.options, (option) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  class: _normalizeClass([
                    option.key == data.value?.choices?.[index] ? 'selected' : 'deleted',
                  ])
                                }, _toDisplayString(option.key + ": " + option.value), 3))
                              }), 256))
                            ]),
                            _: 2
                          }, 1032, ["header"]))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("p", null, "应用类型：" + _toDisplayString(_unref(APP_TYPE_MAP).get(data.value?.appType ?? 99) ?? "未知"), 1),
                _createElementVNode("p", null, " 评分策略：" + _toDisplayString(_unref(APP_SCORING_STRATEGY_MAP).get(data.value?.scoringStrategy ?? 99) ??
              "未知"), 1),
                _createElementVNode("p", null, [
                  _createVNode(_component_a_space, null, {
                    default: _withCtx(() => [
                      _cache[1] || (_cache[1] = _createTextVNode(" 答题人： ")),
                      _createElementVNode("div", _hoisted_2, [
                        (!data.value?.user?.userAvatar || data.value?.user?.userAvatar == '')
                          ? (_openBlock(), _createBlock(_component_a_avatar, {
                              key: 0,
                              "icon:size": "24",
                              style: { marginRight: '8px' }
                            }, {
                              default: _withCtx(() => _cache[0] || (_cache[0] = [
                                _createTextVNode(" A ")
                              ])),
                              _: 1
                            }))
                          : (_openBlock(), _createBlock(_component_a_avatar, {
                              key: 1,
                              "image-url": data.value?.user?.userAvatar,
                              size: 24,
                              style: { marginRight: '8px' }
                            }, null, 8, ["image-url"])),
                        _createVNode(_component_a_typography_text, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(data.value?.user?.userName ?? "无名"), 1)
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("p", null, " 答题时间：" + _toDisplayString(_unref(dayjs)(data.value?.createTime).format("YYYY-MM-DD HH:mm:ss")), 1),
                _createVNode(_component_a_space, { size: "medium" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "primary",
                      href: `/answer/do/${data.value?.appId}`
                    }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode("去答题")
                      ])),
                      _: 1
                    }, 8, ["href"]),
                    _createVNode(_component_a_button, null, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode("分享应用")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, { flex: "320px" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_image, {
                  width: "100%",
                  src: data.value?.resultPicture
                }, null, 8, ["src"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})