import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, unref as _unref, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../assets/logo.png'


const _hoisted_1 = { class: "globalHeader" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

import { routes } from "@/router/routes";
import { useRouter } from "vue-router";
import { computed, ref } from "vue";
import { useLoginUserStore } from "@/store/userStore";
import checkAccess from "@/access/checkAccess";
import { userLogoutUsingPost } from "@/axios/api/userController";
import ACCESS_ENUM from "@/access/accessEnum";
import message from "@arco-design/web-vue/es/message";

export default /*@__PURE__*/_defineComponent({
  __name: 'GlobalHeader',
  setup(__props) {

// import { IconUser } from "@arco-design/web-vue/es/icon";
const loginUserStore = useLoginUserStore();
const router = useRouter();
const selectedKey = ref(["/"]);
// 路由跳转时，自动更新菜单项
router.afterEach((to) => {
  selectedKey.value = [to.path];
});
const visableRoutes = computed(() => {
  return routes.filter((item) => {
    if (item.meta?.hideInMenu) return false;
    // 根据权限过滤菜单
    if (!checkAccess(loginUserStore.loginUser, item.meta?.access as string))
      return false;
    return true;
  });
});
const logout = async () => {
  const res = await userLogoutUsingPost();
  if (res.data.code === 0 && res.data.data) {
    loginUserStore.setLoginUser({ userRole: ACCESS_ENUM.NOT_LOGIN });
    router.push("/user/login");
  } else {
    message.error(res.data.msg);
  }
};
const doMenuClick = (key: string) => {
  router.push({
    path: key,
  });
};

return (_ctx: any,_cache: any) => {
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_row, {
      align: "center",
      wrap: false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, { flex: "auto" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_menu, {
              mode: "horizontal",
              "selected-keys": selectedKey.value,
              onMenuItemClick: doMenuClick
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_menu_item, {
                  key: "0",
                  style: { padding: 0, marginRight: '38px' },
                  disabled: ""
                }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createElementVNode("div", { class: "titleBar" }, [
                      _createElementVNode("img", {
                        src: _imports_0,
                        alt: "",
                        class: "logo"
                      }),
                      _createElementVNode("div", { class: "title" }, "R嗒嗒")
                    ], -1)
                  ])),
                  _: 1
                }),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(visableRoutes.value, (item) => {
                  return (_openBlock(), _createBlock(_component_a_menu_item, {
                    key: item.path
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.name), 1)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }, 8, ["selected-keys"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, { flex: "100px" }, {
          default: _withCtx(() => [
            (_unref(loginUserStore).loginUser.id)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createTextVNode(_toDisplayString(_unref(loginUserStore).loginUser.userName ?? "匿名用户") + " ", 1),
                  _createVNode(_component_a_button, {
                    type: "text",
                    onClick: logout
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode("退出")
                    ])),
                    _: 1
                  })
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_a_button, {
                    type: "primary",
                    href: "/user/login"
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode("登录")
                    ])),
                    _: 1
                  })
                ]))
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})