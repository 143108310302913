<template>
  <a-modal v-model:visible="visible" :footer="false" @cancel="closeModal">
    <template #title>
      {{ title }}
    </template>
    <h4 style="margin-top: 0">复制分享链接：</h4>
    <a-typography-paragraph copyable>{{ link }}</a-typography-paragraph>
    <h4>手机扫描查看</h4>
    <img :src="codeImg" />
  </a-modal>
</template>

<script setup lang="ts">
import { withDefaults, defineProps, ref } from "vue";
// @ts-ignore
import QRCode from "qrcode";
import message from "@arco-design/web-vue/es/message";

interface Props {
  // 分享连接
  link: string;
  // 标题
  title: string;
}
const props = withDefaults(defineProps<Props>(), {
  link: () => "http://resume.richardzhenglab.top",
  title: () => "分享",
});
const codeImg = ref("");

QRCode.toDataURL(props.link)
  .then((url: any) => {
    codeImg.value = url;
  })
  .catch((err: any) => {
    message.error(err);
    message.error("生成二维码失败:" + err.message);
  });

const visible = ref(false);
const openModal = () => {
  visible.value = true;
};
const closeModal = () => {
  visible.value = false;
};

defineExpose({
  openModal,
});
</script>

<style scoped></style>
