<template>
  <div class="globalHeader">
    <a-row align="center" :wrap="false">
      <a-col flex="auto">
        <a-menu
          mode="horizontal"
          :selected-keys="selectedKey"
          @menu-item-click="doMenuClick"
        >
          <a-menu-item
            key="0"
            :style="{ padding: 0, marginRight: '38px' }"
            disabled
          >
            <div class="titleBar">
              <img src="../assets/logo.png" alt="" class="logo" />
              <div class="title">R嗒嗒</div>
            </div>
          </a-menu-item>
          <a-menu-item v-for="item in visableRoutes" :key="item.path">
            {{ item.name }}
          </a-menu-item>
        </a-menu>
      </a-col>
      <a-col flex="100px">
        <div v-if="loginUserStore.loginUser.id">
          {{ loginUserStore.loginUser.userName ?? "匿名用户" }}
          <a-button type="text" @click="logout">退出</a-button>
        </div>
        <div v-else>
          <a-button type="primary" href="/user/login">登录</a-button>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script setup lang="ts">
// import { IconUser } from "@arco-design/web-vue/es/icon";
import { routes } from "@/router/routes";
import { useRouter } from "vue-router";
import { computed, ref } from "vue";
import { useLoginUserStore } from "@/store/userStore";
import checkAccess from "@/access/checkAccess";
import { userLogoutUsingPost } from "@/axios/api/userController";
import ACCESS_ENUM from "@/access/accessEnum";
import message from "@arco-design/web-vue/es/message";
const loginUserStore = useLoginUserStore();
const router = useRouter();
const selectedKey = ref(["/"]);
// 路由跳转时，自动更新菜单项
router.afterEach((to) => {
  selectedKey.value = [to.path];
});
const visableRoutes = computed(() => {
  return routes.filter((item) => {
    if (item.meta?.hideInMenu) return false;
    // 根据权限过滤菜单
    if (!checkAccess(loginUserStore.loginUser, item.meta?.access as string))
      return false;
    return true;
  });
});
const logout = async () => {
  const res = await userLogoutUsingPost();
  if (res.data.code === 0 && res.data.data) {
    loginUserStore.setLoginUser({ userRole: ACCESS_ENUM.NOT_LOGIN });
    router.push("/user/login");
  } else {
    message.error(res.data.msg);
  }
};
const doMenuClick = (key: string) => {
  router.push({
    path: key,
  });
};
</script>

<style scoped>
#globalHeader {
}
.titleBar {
  display: flex;
  align-items: center;
}
.titleBar .logo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.title {
  color: #777;
  font-weight: bold;
  font-size: 24px;
}
</style>
