<template>
  <div id="addAppPage">
    <h2 style="margin-bottom: 32px">创建应用</h2>
    <a-form
      :model="form"
      :style="{ width: '480px' }"
      label-align="left"
      auto-label-width
      @submit="handleSubmit"
    >
      <a-form-item field="appName" label="应用名称">
        <a-input v-model="form.appName" placeholder="请输入应用名称" />
      </a-form-item>
      <a-form-item field="appDesc" label="应用描述">
        <a-input v-model="form.appDesc" placeholder="请输入应用描述" />
      </a-form-item>
      <!-- <a-form-item field="appIcon" label="应用图标">
        <a-input v-model="form.appIcon" placeholder="请输入应用图标" />
      </a-form-item> -->
      <a-form-item field="appIcon" label="应用图标">
        <PictureUploader
          :biz="'app_icon'"
          :value="form.appIcon"
          :onChange = "(value) => form.appIcon = value"
        />
      </a-form-item>
      <a-form-item field="appType" label="应用类型">
        <a-select
          v-model="form.appType"
          :style="{ width: '320px' }"
          placeholder="请选择应用类型"
        >
          <a-option
            v-for="(value, key) of APP_TYPE_MAP"
            :value="Number(value[0])"
            :label="value[1]"
            :key="Number(key)"
          />
        </a-select>
      </a-form-item>
      <a-form-item field="scoringStrategy" label="评分策略">
        <a-select
          v-model="form.scoringStrategy"
          :style="{ width: '320px' }"
          placeholder="请选择评分策略"
        >
          <a-option
            v-for="(value, key) of APP_SCORING_STRATEGY_MAP"
            :value="Number(value[0])"
            :label="value[1]"
            :key="Number(key)"
          />
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button html-type="submit" type="primary" style="width: 120px"
          >提交</a-button
        >
      </a-form-item>
    </a-form>
  </div>
</template>

<script setup lang="ts">
import API from "@/axios/api";
import { userLoginUsingPost } from "@/axios/api/userController";
import { useLoginUserStore } from "@/store/userStore";
import { reactive, withDefaults, defineProps, ref, watchEffect } from "vue";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";
import {
  addAppUsingPost,
  editAppUsingPost,
  getAppVoByIdUsingGet,
} from "@/axios/api/appController";
import { APP_TYPE_MAP, APP_SCORING_STRATEGY_MAP } from "@/constant/appEnum";
import PictureUploader from "@/components/PictureUploader.vue";

interface Props {
  id: number;
}

const props = withDefaults(defineProps<Props>(), {
  id: () => 0,
});
const form = ref({
  appDesc: "",
  appName: "",
  appIcon: "",
  appType: 0,
  scoringStrategy: 0,
} as API.AppAddRequest);
const oldApp = ref<API.AppVO>();
/**
 * 加载数据
 */
const loadData = async () => {
  if (!props.id) return;
  const res = await getAppVoByIdUsingGet({ id: props.id });
  if (res.data.code === 0 && res.data.data) {
    oldApp.value = res.data.data;
    form.value = res.data.data;
  } else {
    message.error("获取数据失败：" + res.data.message);
  }
};
watchEffect(() => {
  loadData();
});
const loginUserStore = useLoginUserStore();
const router = useRouter();
const handleSubmit = async () => {
  let res: any;
  if (props.id) {
    res = await editAppUsingPost({
      id: props.id,
      ...form.value,
    });
  } else {
    res = await addAppUsingPost(form.value);
  }
  if (res.data.code === 0) {
    message.success("操作成功，即将跳转到应用详情页面...");
    setTimeout(() => {
      router.push({
        path: `/app/detail/${props.id || res.data.data}`,
      });
    }, 3000);
  } else {
    message.error("操作失败" + res.data.message);
  }
};
</script>
