import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "adminQuestionPage" }

import { watchEffect } from "vue";
import { ref } from "vue";
import API from "@/axios/api";
import message from "@arco-design/web-vue/es/message";
import { dayjs } from "@arco-design/web-vue/es/_utils/date";

import {
  deleteQuestionUsingPost,
  listQuestionByPageUsingPost,
} from "@/axios/api/questionController";

export default /*@__PURE__*/_defineComponent({
  __name: 'AdminQuestionPage',
  setup(__props) {

const initSearchParams = {
  current: 1,
  pageSize: 10,
};
const searchParams = ref<API.QuestionQueryRequest>({
  ...initSearchParams,
});
const formSearchParams = ref<API.QuestionQueryRequest>({});
const dataList = ref<API.Question[]>();
const total = ref<number>(0);
const loadData = async () => {
  const res = await listQuestionByPageUsingPost(searchParams.value);
  if (res.data.code === 0) {
    dataList.value = res.data.data?.records || [];
    total.value = res.data.data?.total || 0;
  } else {
    message.error("获取数据失败：" + res.data.message);
  }
};
const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};
const doDelete = async (record: API.Question) => {
  if (!record.id) return;
  const res = await deleteQuestionUsingPost({
    id: record.id,
  });
  if (res.data.code === 0) {
    loadData();
  } else {
    message.error("删除失败：" + res.data.message);
  }
};
const doSearch = () => {
  searchParams.value = {
    ...initSearchParams,
    ...formSearchParams.value,
  };
};
watchEffect(() => {
  loadData();
});
const columns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "题目内容",
    dataIndex: "questionContent",
    slotName: "questionContent",
  },
  {
    title: "应用ID",
    dataIndex: "appId",
  },
  {
    title: "用户ID",
    dataIndex: "userId",
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    slotName: "createTime",
  },
  {
    title: "更新时间",
    dataIndex: "updateTime",
    slotName: "updateTime",
  },
  {
    title: "操作",
    slotName: "optional",
  },
];

return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_form, {
      model: searchParams.value,
      style: { marginBottom: '20px' },
      "label-align": "left",
      layout: "inline",
      "auto-label-width": "",
      onSubmit: doSearch
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, {
          field: "appId",
          label: "应用ID"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              "allow-clear": "",
              modelValue: formSearchParams.value.appId,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formSearchParams.value.appId) = $event)),
              placeholder: "请输入应用ID"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          field: "userId",
          label: "用户ID"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              "allow-clear": "",
              modelValue: formSearchParams.value.userId,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((formSearchParams.value.userId) = $event)),
              placeholder: "请输入用户ID"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              "html-type": "submit",
              style: {"width":"120px"}
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("搜索")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"]),
    _createVNode(_component_a_table, {
      columns: columns,
      data: dataList.value,
      pagination: {
        showTotal: true,
        pageSize: searchParams.value.pageSize,
        current: searchParams.value.current,
        total: total.value,
      },
      onPageChange: onPageChange
    }, {
      createTime: _withCtx(({ record }) => [
        _createElementVNode("td", null, _toDisplayString(_unref(dayjs)(record.createTime).format("YYYY-MM-DD HH:mm:ss")), 1)
      ]),
      questionContent: _withCtx(({ record }) => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(JSON.parse(record.questionContent), (question) => {
          return (_openBlock(), _createElementBlock("div", {
            key: question.title
          }, _toDisplayString(question), 1))
        }), 128))
      ]),
      updateTime: _withCtx(({ record }) => [
        _createElementVNode("td", null, _toDisplayString(_unref(dayjs)(record.updateTime).format("YYYY-MM-DD HH:mm:ss")), 1)
      ]),
      optional: _withCtx(({ record }) => [
        _createVNode(_component_a_space, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              status: "danger",
              onClick: ($event: any) => (doDelete(record))
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("删除")
              ])),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }, 8, ["data", "pagination"])
  ]))
}
}

})