import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { id: "addAppPage" }

import API from "@/axios/api";
import { userLoginUsingPost } from "@/axios/api/userController";
import { useLoginUserStore } from "@/store/userStore";
import { reactive, ref, watchEffect } from "vue";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";
import {
  addAppUsingPost,
  editAppUsingPost,
  getAppVoByIdUsingGet,
} from "@/axios/api/appController";
import { APP_TYPE_MAP, APP_SCORING_STRATEGY_MAP } from "@/constant/appEnum";
import PictureUploader from "@/components/PictureUploader.vue";

interface Props {
  id: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AddAppPage',
  props: {
    id: { default: () => 0 }
  },
  setup(__props: any) {

const props = __props;
const form = ref({
  appDesc: "",
  appName: "",
  appIcon: "",
  appType: 0,
  scoringStrategy: 0,
} as API.AppAddRequest);
const oldApp = ref<API.AppVO>();
/**
 * 加载数据
 */
const loadData = async () => {
  if (!props.id) return;
  const res = await getAppVoByIdUsingGet({ id: props.id });
  if (res.data.code === 0 && res.data.data) {
    oldApp.value = res.data.data;
    form.value = res.data.data;
  } else {
    message.error("获取数据失败：" + res.data.message);
  }
};
watchEffect(() => {
  loadData();
});
const loginUserStore = useLoginUserStore();
const router = useRouter();
const handleSubmit = async () => {
  let res: any;
  if (props.id) {
    res = await editAppUsingPost({
      id: props.id,
      ...form.value,
    });
  } else {
    res = await addAppUsingPost(form.value);
  }
  if (res.data.code === 0) {
    message.success("操作成功，即将跳转到应用详情页面...");
    setTimeout(() => {
      router.push({
        path: `/app/detail/${props.id || res.data.data}`,
      });
    }, 3000);
  } else {
    message.error("操作失败" + res.data.message);
  }
};

return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_option = _resolveComponent("a-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[5] || (_cache[5] = _createElementVNode("h2", { style: {"margin-bottom":"32px"} }, "创建应用", -1)),
    _createVNode(_component_a_form, {
      model: form.value,
      style: { width: '480px' },
      "label-align": "left",
      "auto-label-width": "",
      onSubmit: handleSubmit
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, {
          field: "appName",
          label: "应用名称"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              modelValue: form.value.appName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.appName) = $event)),
              placeholder: "请输入应用名称"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          field: "appDesc",
          label: "应用描述"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              modelValue: form.value.appDesc,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.value.appDesc) = $event)),
              placeholder: "请输入应用描述"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          field: "appIcon",
          label: "应用图标"
        }, {
          default: _withCtx(() => [
            _createVNode(PictureUploader, {
              biz: 'app_icon',
              value: form.value.appIcon,
              onChange: (value) => form.value.appIcon = value
            }, null, 8, ["value", "onChange"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          field: "appType",
          label: "应用类型"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_select, {
              modelValue: form.value.appType,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((form.value.appType) = $event)),
              style: { width: '320px' },
              placeholder: "请选择应用类型"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(APP_TYPE_MAP), (value, key) => {
                  return (_openBlock(), _createBlock(_component_a_option, {
                    value: Number(value[0]),
                    label: value[1],
                    key: Number(key)
                  }, null, 8, ["value", "label"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          field: "scoringStrategy",
          label: "评分策略"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_select, {
              modelValue: form.value.scoringStrategy,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((form.value.scoringStrategy) = $event)),
              style: { width: '320px' },
              placeholder: "请选择评分策略"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(APP_SCORING_STRATEGY_MAP), (value, key) => {
                  return (_openBlock(), _createBlock(_component_a_option, {
                    value: Number(value[0]),
                    label: value[1],
                    key: Number(key)
                  }, null, 8, ["value", "label"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              "html-type": "submit",
              type: "primary",
              style: {"width":"120px"}
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("提交")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"])
  ]))
}
}

})