<template>
  <div id="answerResultPage">
    <a-card>
      <a-row style="margin-bottom: 16px">
        <a-col flex="auto" class="content-wrapper">
          <h2>{{ data?.resultName }}</h2>
          <p>结果描述：{{ data?.resultDesc }}</p>
          <p>结果 ID: {{ data?.resultId }}</p>
          <p>应用 ID: {{ data?.appId }}</p>
          <p>结果得分：{{ data?.resultScore }}</p>
          <p>我的答案：{{ data?.choices }}</p>
          <a-collapse :default-active-key="['1']" :bordered="false">
            <a-collapse-item header="答案回顾">
              <a-collapse-item
                v-for="(question, index) in questionContent"
                :header="
                  question.title + '。 你的答案：' + data?.choices?.[index]
                "
                :key="index"
              >
                <div
                  v-for="option in question.options"
                  :class="[
                    option.key == data?.choices?.[index] ? 'selected' : 'deleted',
                  ]"
                >
                  {{ option.key + ": " + option.value }}
                </div>
              </a-collapse-item>
            </a-collapse-item>
          </a-collapse>
          <p>应用类型：{{ APP_TYPE_MAP.get(data?.appType ?? 99) ?? "未知" }}</p>
          <p>
            评分策略：{{
              APP_SCORING_STRATEGY_MAP.get(data?.scoringStrategy ?? 99) ??
              "未知"
            }}
          </p>
          <p>
            <a-space>
              答题人：
              <div
                :style="{
                  display: 'flex',
                  alignItems: 'center',
                }"
              >
                <a-avatar
                  v-if="!data?.user?.userAvatar || data?.user?.userAvatar == ''"
                  icon:size="24"
                  :style="{ marginRight: '8px' }"
                >
                  A
                </a-avatar>
                <a-avatar
                  v-else
                  :image-url="data?.user?.userAvatar"
                  :size="24"
                  :style="{ marginRight: '8px' }"
                />
                <a-typography-text>{{
                  data?.user?.userName ?? "无名"
                }}</a-typography-text>
              </div>
            </a-space>
          </p>
          <p>
            答题时间：{{
              dayjs(data?.createTime).format("YYYY-MM-DD HH:mm:ss")
            }}
          </p>
          <a-space size="medium">
            <a-button type="primary" :href="`/answer/do/${data?.appId}`"
              >去答题</a-button
            >
            <a-button>分享应用</a-button>
          </a-space>
        </a-col>
        <a-col flex="320px">
          <a-image width="100%" :src="data?.resultPicture" />
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script setup lang="ts">
import API from "@/axios/api";
import { getUserAnswerVoByIdUsingGet } from "@/axios/api/userAnswerController";
import { defineProps, withDefaults, ref, watchEffect, computed } from "vue";
import message from "@arco-design/web-vue/es/message";
import { dayjs } from "@arco-design/web-vue/es/_utils/date";
import { APP_TYPE_MAP, APP_SCORING_STRATEGY_MAP } from "@/constant/appEnum";
import { useLoginUserStore } from "@/store/userStore";
import { listQuestionVoByPageUsingPost } from "@/axios/api/questionController";
interface Props {
  id: number;
}

const props = withDefaults(defineProps<Props>(), {
  id: () => 0,
});
const data = ref<API.UserAnswerVO>();
const loginUserStore = useLoginUserStore();
let loginUserId = loginUserStore.loginUser?.id;
// 是否為本人創建
const isMy = computed(() => {
  return loginUserId && loginUserId == data.value?.userId;
});
// 我的答案的遍历
const questionContent = ref<API.QuestionContentDTO[]>([]);
const loadQuestionContent = async () => {
  // 获取题目
  const res = await listQuestionVoByPageUsingPost({
    appId: data.value?.appId,
    current: 1,
    pageSize: 1,
    sortField: "createTime",
    sortOrder: "descend",
  });
  if (res.data.code === 0 && res.data.data?.records) {
    questionContent.value = res.data.data?.records[0]?.questionContent || [];
  } else {
    message.error("获取题目失败：" + res.data.message);
  }
};

const loadData = async () => {
  if (!props.id) return;
  const res = await getUserAnswerVoByIdUsingGet({ id: props.id });
  if (res.data.code === 0) {
    data.value = res.data.data;
  } else {
    message.error("获取数据失败：" + res.data.message);
  }
  loadQuestionContent();
};
watchEffect(() => {
  loadData();
});
</script>

<style scoped>
#answerResultPage {
}
#answerResultPage .content-wrapper > * {
  margin-bottom: 24px;
}
#answerResultPage .selected {
  color: red;
}
#answerResultPage .deleted {
  text-decoration: line-through;
  color: #999
}
</style>
