import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "addQuestionPage" }

import API from "@/axios/api";
import { userLoginUsingPost } from "@/axios/api/userController";
import { useLoginUserStore } from "@/store/userStore";
import { reactive, ref, watchEffect } from "vue";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";
import {
  addAppUsingPost,
  editAppUsingPost,
  getAppVoByIdUsingGet,
} from "@/axios/api/appController";
import { APP_TYPE_MAP, APP_SCORING_STRATEGY_MAP } from "@/constant/appEnum";
import {
  addQuestionUsingPost,
  editQuestionUsingPost,
  listQuestionVoByPageUsingPost,
} from "@/axios/api/questionController";
import AiGenerateQuestionDrawer from "@/views/add/components/AiGenerateQuestionDrawer.vue";
// import PictureUploader from "@/components/PictureUploader.vue";

interface Props {
  appId: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AddQuestionPage',
  props: {
    appId: { default: () => 0 }
  },
  setup(__props: any) {

const props = __props;
// 题目内容结构
const questionContent = ref<API.QuestionContentDTO[]>([]);
/**
 * 添加题目
 * @param index
 */
const addQuestion = (index: number) => {
  questionContent.value.splice(index, 0, {
    title: "",
    options: [],
  });
};
/**
 * 删除题目
 * @param index
 */
const deleteQuestion = (index: number) => {
  questionContent.value.splice(index, 1);
};
/**
 * 添加题目选项
 * @param question
 * @param index
 */
const addQuestionOption = (question: API.QuestionContentDTO, index: number) => {
  if (!question.options) question.options = [];
  question.options.splice(index, 0, {
    key: "",
    value: "",
  });
};
/**
 * 删除题目选项
 * @param question
 * @param index
 */
const deleteQuestionOption = (
  question: API.QuestionContentDTO,
  index: number
) => {
  if (!question.options) question.options = [];
  question.options.splice(index, 1);
};
const oldQuestion = ref<API.QuestionVO>();
/**
 * 加载数据
 */
const loadData = async () => {
  if (!props.appId) return;
  const res = await listQuestionVoByPageUsingPost({
    appId: props.appId,
    current: 1,
    pageSize: 1,
    sortField: "createTime",
    sortOrder: "descend",
  });
  if (res.data.code === 0 && res.data.data?.records) {
    oldQuestion.value = res.data.data?.records[0];
    if (oldQuestion.value) {
      questionContent.value = oldQuestion.value.questionContent ?? [];
    }
  } else {
    message.error("获取数据失败：" + res.data.message);
  }
};
watchEffect(() => {
  loadData();
});
const loginUserStore = useLoginUserStore();
const router = useRouter();
const handleSubmit = async () => {
  if (!props.appId || !questionContent.value) return;
  let res: any;
  if (oldQuestion.value?.id) {
    res = await editQuestionUsingPost({
      id: oldQuestion.value.id,
      questionContent: questionContent.value,
    });
  } else {
    res = await addQuestionUsingPost({
      appId: props.appId,
      questionContent: questionContent.value,
    });
  }
  if (res.data.code === 0) {
    message.success("操作成功，即将跳转到应用详情页面...");
    setTimeout(() => {
      router.push({
        path: `/app/detail/${props.appId}`,
      });
    }, 3000);
  } else {
    message.error("操作失败" + res.data.message);
  }
};
/**
 * AI生成题目成功后执行
 */
const onAiGenerateSuccess = (result: API.QuestionContentDTO[]) => {
  questionContent.value = [...questionContent.value, ...result];
};
/**
 * AI生成题目成功后执行(SSE)
 */
 const onAiGenerateSuccessSSE = (result: API.QuestionContentDTO[]) => {
  // @ts-ignore
  questionContent.value = [...questionContent.value, result];
};
/**
 * SSE 开始生成
 * @param event 
 */
const onSSEOpen = (event: Event) => {
  message.success("开始生成题目");
}
/**
 * SSE 生成成功
 * @param event 
 */
const onSSEClose = (event: Event) => {
  message.success("生成题目成功");
}

return (_ctx: any,_cache: any) => {
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[8] || (_cache[8] = _createElementVNode("h2", { style: {"margin-bottom":"32px"} }, "设置题目", -1)),
    _createVNode(_component_a_form, {
      model: questionContent.value,
      style: { width: '480px' },
      "label-align": "left",
      "auto-label-width": "",
      onSubmit: handleSubmit
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, { label: "应用 ID" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.appId), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          label: "题目列表",
          "content-flex": false,
          "merge-props": false
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_space, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (addQuestion(questionContent.value.length)))
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode("底部添加题目")
                  ])),
                  _: 1
                }),
                _createVNode(AiGenerateQuestionDrawer, {
                  appId: _ctx.appId,
                  onSuccess: onAiGenerateSuccess,
                  onSSESuccess: onAiGenerateSuccessSSE,
                  onSSEClose: onSSEClose,
                  onSSEOpen: onSSEOpen
                }, null, 8, ["appId"])
              ]),
              _: 1
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(questionContent.value, (question, index) => {
              return (_openBlock(), _createElementBlock("div", { key: index }, [
                _createVNode(_component_a_card, {
                  hoverable: "",
                  style: {"margin-bottom":"4px"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_space, null, {
                      default: _withCtx(() => [
                        _createElementVNode("h3", null, "题目 " + _toDisplayString(index + 1), 1),
                        _createVNode(_component_a_button, {
                          onClick: ($event: any) => (addQuestion(index + 1))
                        }, {
                          default: _withCtx(() => _cache[2] || (_cache[2] = [
                            _createTextVNode("添加题目")
                          ])),
                          _: 2
                        }, 1032, ["onClick"]),
                        _createVNode(_component_a_button, {
                          status: "danger",
                          onClick: ($event: any) => (deleteQuestion(index))
                        }, {
                          default: _withCtx(() => _cache[3] || (_cache[3] = [
                            _createTextVNode("删除题目")
                          ])),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_a_form_item, {
                      field: "question.title",
                      label: `题目 ${index + 1} 标题`
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          modelValue: question.title,
                          "onUpdate:modelValue": ($event: any) => ((question.title) = $event),
                          placeholder: "请输入题目"
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ]),
                      _: 2
                    }, 1032, ["label"]),
                    _createVNode(_component_a_space, null, {
                      default: _withCtx(() => [
                        _createElementVNode("h4", null, "题目" + _toDisplayString(index + 1) + "选项列表", 1),
                        _createVNode(_component_a_button, {
                          onClick: ($event: any) => (
                  addQuestionOption(question, (question.options ?? []).length)
                )
                        }, {
                          default: _withCtx(() => _cache[4] || (_cache[4] = [
                            _createTextVNode("底部添加题目")
                          ])),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(question.options, (option, optionIndex) => {
                      return (_openBlock(), _createBlock(_component_a_form_item, {
                        key: optionIndex,
                        label: `选项${optionIndex + 1}`,
                        "content-flex": false,
                        "merge-props": false,
                        style: {"border-bottom":"1px solid #ccc","padding-bottom":"16px"}
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { label: "选项 key" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                modelValue: option.key,
                                "onUpdate:modelValue": ($event: any) => ((option.key) = $event),
                                placeholder: "请输入选项 key"
                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_a_form_item, { label: "选项值" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                modelValue: option.value,
                                "onUpdate:modelValue": ($event: any) => ((option.value) = $event),
                                placeholder: "请输入选项值"
                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_a_form_item, { label: "选项结果" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                modelValue: option.result,
                                "onUpdate:modelValue": ($event: any) => ((option.result) = $event),
                                placeholder: "请输入选项结果"
                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_a_form_item, { label: "选项得分" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_input_number, {
                                modelValue: option.score,
                                "onUpdate:modelValue": ($event: any) => ((option.score) = $event),
                                placeholder: "请输入选项得分"
                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_a_space, { size: "large" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_button, {
                                size: "mini",
                                onClick: ($event: any) => (addQuestionOption(question, optionIndex + 1))
                              }, {
                                default: _withCtx(() => _cache[5] || (_cache[5] = [
                                  _createTextVNode("添加选项")
                                ])),
                                _: 2
                              }, 1032, ["onClick"]),
                              _createVNode(_component_a_button, {
                                size: "mini",
                                status: "danger",
                                onClick: ($event: any) => (deleteQuestionOption(question, optionIndex))
                              }, {
                                default: _withCtx(() => _cache[6] || (_cache[6] = [
                                  _createTextVNode("删除选项")
                                ])),
                                _: 2
                              }, 1032, ["onClick"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["label"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1024)
              ]))
            }), 128))
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              "html-type": "submit",
              type: "primary",
              style: {"width":"120px"}
            }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode("提交")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"])
  ]))
}
}

})