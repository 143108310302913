export enum REVIEW_STATUS_ENUM {
  // 待审核
  REVIEWING = 0,
  // 通过
  PASS = 1,
  // 拒绝
  REJECT = 2,
}
export const REVIEW_STATUS_MAP = new Map<number, string>([
  [REVIEW_STATUS_ENUM.REVIEWING, "待审核"],
  [REVIEW_STATUS_ENUM.PASS, "通过"],
  [REVIEW_STATUS_ENUM.REJECT, "拒绝"],
]);

export enum APP_TYPE_ENUM {
  // 得分类
  SCORE = 0,
  // 测评类
  TEST = 1,
}
export const APP_TYPE_MAP = new Map<number, string>([
  [0, "得分类"],
  [1, "测评类"],
]);


export enum APP_SCORING_STRATEGY_ENUM {
  // 自定义
  COSTUME = 0,
  // AI
  AI = 1,
}
export const APP_SCORING_STRATEGY_MAP = new Map<number, string>([
  [APP_SCORING_STRATEGY_ENUM.COSTUME, "自定义"],
  [APP_SCORING_STRATEGY_ENUM.AI, "AI"],
]);