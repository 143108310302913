<template>
  <div id="appDetailPage">
    <a-card>
      <a-row style="margin-bottom: 16px">
        <a-col flex="auto" class="content-wrapper">
          <h2>{{ data?.appName }}</h2>
          <p>{{ data?.appDesc }}</p>
          <p>应用类型：{{ APP_TYPE_MAP.get(data?.appType ?? 99) ?? "未知" }}</p>
          <p>
            评分策略：{{
              APP_SCORING_STRATEGY_MAP.get(data?.scoringStrategy ?? 99) ??
              "未知"
            }}
          </p>
          <p>
            <a-space>
              作者：
              <div
                :style="{
                  display: 'flex',
                  alignItems: 'center',
                }"
              >
                <a-avatar
                  v-if="!data?.user?.userAvatar || data?.user?.userAvatar == ''"
                  icon:size="24"
                  :style="{ marginRight: '8px' }"
                >
                  A
                </a-avatar>
                <a-avatar
                  v-else
                  :image-url="data?.user?.userAvatar"
                  :size="24"
                  :style="{ marginRight: '8px' }"
                />
                <a-typography-text>{{
                  data?.user?.userName ?? "无名"
                }}</a-typography-text>
              </div>
            </a-space>
          </p>
          <p>
            创建时间：{{
              dayjs(data?.createTime).format("YYYY-MM-DD HH:mm:ss")
            }}
          </p>
          <a-space size="medium">
            <a-button type="primary" :href="`/answer/do/${id}`"
              >开始答题</a-button
            >
            <a-button @click="doShare">分享应用</a-button>
            <a-button v-if="isMy" :href="`/add/question/${id}`"
              >设置题目</a-button
            >
            <a-button v-if="isMy" :href="`/add/scoring_result/${id}`"
              >设置评分</a-button
            >
            <a-button v-if="isMy" :href="`/add/app/${id}`">修改应用</a-button>
          </a-space>
        </a-col>
        <a-col flex="320px">
          <a-image width="100%" :src="data?.appIcon" />
        </a-col>
      </a-row>
    </a-card>
  </div>
  <ShareModal :link="shareLink" title="应用分享" ref="ShareModalRef" />
</template>

<script setup lang="ts">
import API from "@/axios/api";
import { getAppVoByIdUsingGet } from "@/axios/api/appController";
import { defineProps, withDefaults, ref, watchEffect, computed } from "vue";
import message from "@arco-design/web-vue/es/message";
import { dayjs } from "@arco-design/web-vue/es/_utils/date";
import { APP_TYPE_MAP, APP_SCORING_STRATEGY_MAP } from "@/constant/appEnum";
import { useLoginUserStore } from "@/store/userStore";
import ShareModal from "@/components/ShareModal.vue";

interface Props {
  id: number;
}

const props = withDefaults(defineProps<Props>(), {
  id: () => 0,
});
const data = ref<API.AppVO>();
const loginUserStore = useLoginUserStore();
let loginUserId = loginUserStore.loginUser?.id;
// 是否為本人創建
const isMy = computed(() => {
  return loginUserId && loginUserId == data.value?.userId;
});

const loadData = async () => {
  if (!props.id) return;
  const res = await getAppVoByIdUsingGet({ id: props.id });
  if (res.data.code === 0) {
    data.value = res.data.data;
  } else {
    message.error("获取数据失败：" + res.data.message);
  }
};
watchEffect(() => {
  loadData();
});
// 分享
const shareLink = ref(
  `${window.location.protocol}//${window.location.host}/app/detail/${props.id}`
);
const ShareModalRef = ref();
const doShare = (e: Event) => {
  if (ShareModalRef.value) {
    ShareModalRef.value.openModal();
  }
  // 阻止冒泡，防止跳转到详情页
  e.stopPropagation();
};
</script>

<style scoped>
#appDetailPage {
}
#appDetailPage .content-wrapper > * {
  margin-bottom: 24px;
}
</style>
