import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "adminUserPage" }

import { watchEffect } from "vue";
import { ref } from "vue";
import API from "@/axios/api";
import message from "@arco-design/web-vue/es/message";
import { dayjs } from "@arco-design/web-vue/es/_utils/date";

import {
  deleteUserUsingPost,
  listUserByPageUsingPost,
} from "@/axios/api/userController";

export default /*@__PURE__*/_defineComponent({
  __name: 'AdminUserPage',
  setup(__props) {

const initSearchParams = {
  current: 1,
  pageSize: 10,
};
const searchParams = ref<API.UserQueryRequest>({
  ...initSearchParams,
});
const formSearchParams = ref<API.UserQueryRequest>({});
const dataList = ref<API.User[]>();
const total = ref<number>(0);
const loadData = async () => {
  const res = await listUserByPageUsingPost(searchParams.value);
  if (res.data.code === 0) {
    dataList.value = res.data.data?.records || [];
    total.value = res.data.data?.total || 0;
  } else {
    message.error("获取数据失败：" + res.data.message);
  }
};
const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};
const doDelete = async (record: API.User) => {
  if (!record.id) return;
  const res = await deleteUserUsingPost({
    id: record.id,
  });
  if (res.data.code === 0) {
    loadData();
  } else {
    message.error("删除失败：" + res.data.message);
  }
};
const doSearch = () => {
  searchParams.value = {
    ...initSearchParams,
    ...formSearchParams.value,
  };
};
watchEffect(() => {
  loadData();
});
const columns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "账号",
    dataIndex: "userAccount",
  },
  {
    title: "用户名",
    dataIndex: "userName",
  },
  {
    title: "用户头像",
    dataIndex: "userAvatar",
    slotName: "userAvatar",
  },
  {
    title: "用户简介",
    dataIndex: "userProfile",
  },
  {
    title: "权限",
    dataIndex: "userRole",
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    slotName: "createTime",
  },
  {
    title: "更新时间",
    dataIndex: "updateTime",
    slotName: "updateTime",
  },
  {
    title: "操作",
    slotName: "optional",
  },
];

return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_form, {
      model: searchParams.value,
      style: { marginBottom: '20px' },
      "label-align": "left",
      layout: "inline",
      "auto-label-width": "",
      onSubmit: doSearch
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, {
          field: "userName",
          label: "用户名"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              "allow-clear": "",
              modelValue: formSearchParams.value.userName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formSearchParams.value.userName) = $event)),
              placeholder: "请输入用户名"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          field: "userProfile",
          label: "用户简介"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              "allow-clear": "",
              modelValue: formSearchParams.value.userProfile,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((formSearchParams.value.userProfile) = $event)),
              placeholder: "请输入用户简介"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              "html-type": "submit",
              style: {"width":"120px"}
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("搜索")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"]),
    _createVNode(_component_a_table, {
      columns: columns,
      data: dataList.value,
      pagination: {
        showTotal: true,
        pageSize: searchParams.value.pageSize,
        current: searchParams.value.current,
        total: total.value,
      },
      onPageChange: onPageChange
    }, {
      userAvatar: _withCtx(({ record }) => [
        _createVNode(_component_a_image, {
          width: "32",
          src: record.userAvatar
        }, null, 8, ["src"])
      ]),
      createTime: _withCtx(({ record }) => [
        _createElementVNode("td", null, _toDisplayString(_unref(dayjs)(record.createTime).format("YYYY-MM-DD HH:mm:ss")), 1)
      ]),
      updateTime: _withCtx(({ record }) => [
        _createElementVNode("td", null, _toDisplayString(_unref(dayjs)(record.updateTime).format("YYYY-MM-DD HH:mm:ss")), 1)
      ]),
      optional: _withCtx(({ record }) => [
        _createVNode(_component_a_space, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              status: "danger",
              onClick: ($event: any) => (doDelete(record))
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("删除")
              ])),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }, 8, ["data", "pagination"])
  ]))
}
}

})