import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "arco-upload-list-picture custom-upload-avatar"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "arco-upload-list-picture-mask" }
const _hoisted_4 = {
  key: 1,
  class: "arco-upload-picture-card"
}
const _hoisted_5 = { class: "arco-upload-picture-card-text" }

import { uploadFileUsingPost } from "@/axios/api/fileController";
import { Message } from "@arco-design/web-vue";
import { IconEdit, IconPlus } from "@arco-design/web-vue/es/icon";
import { ref, watchEffect } from "vue";
interface Props {
  biz: string;
  onChange?: (url: string) => void;
  value?: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'PictureUploader',
  props: {
    biz: {},
    onChange: {},
    value: { default: () => "" }
  },
  setup(__props: any) {

const props = __props;
const file = ref();
watchEffect(() => {
  if (props.value) {
    file.value = {
      url: props.value,
      percent: 100,
      status: "done",
    };
  }
});

// 自定义请求
// eslint-disable-next-line
const customRequest = async (options: any) => {
  const { onError, onSuccess, fileItem } = options;
  // eslint-disable-next-line
  const res: any = await uploadFileUsingPost(
    { biz: props.biz },
    {},
    fileItem.file
  );
  if (res.data.code === 0 && res.data.data) {
    const url = res.data.data;
    file.value = {
      name: fileItem.name,
      file: fileItem.file,
      url,
    };
    props.onChange?.(url);
    onSuccess();
    console.log(file.value);
  } else {
    Message.error("上传失败" + res.data.message || "");
    onError(new Error(res.data.message));
  }
};

return (_ctx: any,_cache: any) => {
  const _component_a_progress = _resolveComponent("a-progress")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_a_space = _resolveComponent("a-space")!

  return (_openBlock(), _createBlock(_component_a_space, {
    direction: "vertical",
    style: { width: '100%' }
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_upload, {
        fileList: file.value ? [file.value] : [],
        "show-file-list": false,
        "custom-request": customRequest
      }, {
        "upload-button": _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(`arco-upload-list-item${
            file.value && file.value.status === 'error'
              ? ' arco-upload-list-item-error'
              : ''
          }`)
          }, [
            (file.value && file.value.url)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createElementVNode("img", {
                    src: file.value.url
                  }, null, 8, _hoisted_2),
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_unref(IconEdit))
                  ]),
                  (file.value.status === 'uploading' && file.value.percent < 100)
                    ? (_openBlock(), _createBlock(_component_a_progress, {
                        key: 0,
                        percent: file.value.percent,
                        type: "circle",
                        size: "mini",
                        style: {
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translateX(-50%) translateY(-50%)',
              }
                      }, null, 8, ["percent"]))
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_unref(IconPlus)),
                    _cache[0] || (_cache[0] = _createElementVNode("div", { style: {"margin-top":"10px","font-weight":"600"} }, "上传", -1))
                  ])
                ]))
          ], 2)
        ]),
        _: 1
      }, 8, ["fileList"])
    ]),
    _: 1
  }))
}
}

})