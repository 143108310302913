import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "appDetailPage" }
const _hoisted_2 = { style: {
                  display: 'flex',
                  alignItems: 'center',
                } }

import API from "@/axios/api";
import { getAppVoByIdUsingGet } from "@/axios/api/appController";
import { ref, watchEffect, computed } from "vue";
import message from "@arco-design/web-vue/es/message";
import { dayjs } from "@arco-design/web-vue/es/_utils/date";
import { APP_TYPE_MAP, APP_SCORING_STRATEGY_MAP } from "@/constant/appEnum";
import { useLoginUserStore } from "@/store/userStore";
import ShareModal from "@/components/ShareModal.vue";

interface Props {
  id: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppDetailPage',
  props: {
    id: { default: () => 0 }
  },
  setup(__props: any) {

const props = __props;
const data = ref<API.AppVO>();
const loginUserStore = useLoginUserStore();
let loginUserId = loginUserStore.loginUser?.id;
// 是否為本人創建
const isMy = computed(() => {
  return loginUserId && loginUserId == data.value?.userId;
});

const loadData = async () => {
  if (!props.id) return;
  const res = await getAppVoByIdUsingGet({ id: props.id });
  if (res.data.code === 0) {
    data.value = res.data.data;
  } else {
    message.error("获取数据失败：" + res.data.message);
  }
};
watchEffect(() => {
  loadData();
});
// 分享
const shareLink = ref(
  `${window.location.protocol}//${window.location.host}/app/detail/${props.id}`
);
const ShareModalRef = ref();
const doShare = (e: Event) => {
  if (ShareModalRef.value) {
    ShareModalRef.value.openModal();
  }
  // 阻止冒泡，防止跳转到详情页
  e.stopPropagation();
};

return (_ctx: any,_cache: any) => {
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_row, { style: {"margin-bottom":"16px"} }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, {
                flex: "auto",
                class: "content-wrapper"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h2", null, _toDisplayString(data.value?.appName), 1),
                  _createElementVNode("p", null, _toDisplayString(data.value?.appDesc), 1),
                  _createElementVNode("p", null, "应用类型：" + _toDisplayString(_unref(APP_TYPE_MAP).get(data.value?.appType ?? 99) ?? "未知"), 1),
                  _createElementVNode("p", null, " 评分策略：" + _toDisplayString(_unref(APP_SCORING_STRATEGY_MAP).get(data.value?.scoringStrategy ?? 99) ??
              "未知"), 1),
                  _createElementVNode("p", null, [
                    _createVNode(_component_a_space, null, {
                      default: _withCtx(() => [
                        _cache[1] || (_cache[1] = _createTextVNode(" 作者： ")),
                        _createElementVNode("div", _hoisted_2, [
                          (!data.value?.user?.userAvatar || data.value?.user?.userAvatar == '')
                            ? (_openBlock(), _createBlock(_component_a_avatar, {
                                key: 0,
                                "icon:size": "24",
                                style: { marginRight: '8px' }
                              }, {
                                default: _withCtx(() => _cache[0] || (_cache[0] = [
                                  _createTextVNode(" A ")
                                ])),
                                _: 1
                              }))
                            : (_openBlock(), _createBlock(_component_a_avatar, {
                                key: 1,
                                "image-url": data.value?.user?.userAvatar,
                                size: 24,
                                style: { marginRight: '8px' }
                              }, null, 8, ["image-url"])),
                          _createVNode(_component_a_typography_text, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(data.value?.user?.userName ?? "无名"), 1)
                            ]),
                            _: 1
                          })
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("p", null, " 创建时间：" + _toDisplayString(_unref(dayjs)(data.value?.createTime).format("YYYY-MM-DD HH:mm:ss")), 1),
                  _createVNode(_component_a_space, { size: "medium" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        href: `/answer/do/${_ctx.id}`
                      }, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createTextVNode("开始答题")
                        ])),
                        _: 1
                      }, 8, ["href"]),
                      _createVNode(_component_a_button, { onClick: doShare }, {
                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                          _createTextVNode("分享应用")
                        ])),
                        _: 1
                      }),
                      (isMy.value)
                        ? (_openBlock(), _createBlock(_component_a_button, {
                            key: 0,
                            href: `/add/question/${_ctx.id}`
                          }, {
                            default: _withCtx(() => _cache[4] || (_cache[4] = [
                              _createTextVNode("设置题目")
                            ])),
                            _: 1
                          }, 8, ["href"]))
                        : _createCommentVNode("", true),
                      (isMy.value)
                        ? (_openBlock(), _createBlock(_component_a_button, {
                            key: 1,
                            href: `/add/scoring_result/${_ctx.id}`
                          }, {
                            default: _withCtx(() => _cache[5] || (_cache[5] = [
                              _createTextVNode("设置评分")
                            ])),
                            _: 1
                          }, 8, ["href"]))
                        : _createCommentVNode("", true),
                      (isMy.value)
                        ? (_openBlock(), _createBlock(_component_a_button, {
                            key: 2,
                            href: `/add/app/${_ctx.id}`
                          }, {
                            default: _withCtx(() => _cache[6] || (_cache[6] = [
                              _createTextVNode("修改应用")
                            ])),
                            _: 1
                          }, 8, ["href"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, { flex: "320px" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_image, {
                    width: "100%",
                    src: data.value?.appIcon
                  }, null, 8, ["src"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createVNode(ShareModal, {
      link: shareLink.value,
      title: "应用分享",
      ref_key: "ShareModalRef",
      ref: ShareModalRef
    }, null, 8, ["link"])
  ], 64))
}
}

})