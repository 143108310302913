<template>
  <a-button type="outline" @click="handleClick">AI 生成题目</a-button>
  <a-drawer
    :width="340"
    :visible="visible"
    @ok="handleOk"
    @cancel="handleCancel"
    unmountOnClose
  >
    <template #title> AI 生成题目 </template>
    <div>
      <a-form
        :model="form"
        label-align="left"
        auto-label-width
        @submit="handleSubmit"
      >
        <a-form-item label="应用 ID">
          {{ appId }}
        </a-form-item>
        <a-form-item field="questionNumber" label="题目数量">
          <a-input-number
            v-model="form.questionNumber"
            placeholder="请输入题目数量"
          />
        </a-form-item>
        <a-form-item field="optionNumber" label="选项数量">
          <a-input-number
            v-model="form.optionNumber"
            placeholder="请输入选项数量"
          />
        </a-form-item>
        <a-form-item>
          <a-space>
            <a-button
              :loading="submitting"
              html-type="submit"
              type="primary"
              style="width: 120px"
              :disabled="disabled"
              >{{ submitting ? "生成中" : "一键生成" }}</a-button
            >
            <a-button
              :loading="sseSubmitting"
              style="width: 120px"
              @click="handleSSESubmit"
              >{{ sseSubmitting ? "生成中" : "实时生成" }}</a-button
            >
          </a-space>
        </a-form-item>
      </a-form>
    </div>
  </a-drawer>
</template>

<script setup lang="ts">
import API from "@/axios/api";
import { userLoginUsingPost } from "@/axios/api/userController";
import { useLoginUserStore } from "@/store/userStore";
import {
  reactive,
  withDefaults,
  defineProps,
  ref,
  watchEffect,
  defineEmits,
} from "vue";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";
import { aiGenerateQuestionUsingPost } from "@/axios/api/questionController";
import { APP_TYPE_MAP, APP_SCORING_STRATEGY_MAP } from "@/constant/appEnum";
// import PictureUploader from "@/components/PictureUploader.vue";

interface Props {
  appId: number;
  onSuccess?: (result: API.QuestionContentDTO[]) => void;
  onSSESuccess?: (result: API.QuestionContentDTO[]) => void;
  onSSEOpen?: (event: any) => void;
  onSSEClose?: (event: any) => void;
}
const emit = defineEmits(["success"]);
const props = withDefaults(defineProps<Props>(), {
  appId: () => 0,
});

const form = ref({
  optionNumber: 2,
  questionNumber: 10,
} as API.AiGenerateQuestionRequest);
const visible = ref(false);
const submitting = ref(false);
const disabled = ref(false);
const sseSubmitting = ref(false);

const handleClick = () => {
  visible.value = true;
};
const handleOk = () => {
  visible.value = false;
};
const handleCancel = () => {
  visible.value = false;
};

/**
 * 提交
 */
const handleSubmit = async (data: any) => {
  if (!props.appId) return;

  submitting.value = true;
  const res = await aiGenerateQuestionUsingPost({
    appId: props.appId,
    ...form.value,
  });

  // @ts-ignore
  if (res.data.code === 0 && res.data.data.length > 0) {
    // 1. 使用props 传递参数（function）
    // if (props.onSuccess) {
    //   props.onSuccess(res.data.data);
    // }
    // 2. emit 绑定事件，传递参数
    emit("success", res.data.data);
    message.success("生成题目成功");
    handleCancel();
  } else {
    message.error("操作失败" + res.data.message);
  }
  submitting.value = false;
};
/**
 * 提交(实时生成)
 */
const handleSSESubmit = async () => {
  if (!props.appId) return;

  // TODO: 手动编写完整的后端地址
  const eventSource = new EventSource(
    "http://localhost:8101/api/question/ai_generate/sse" +
      `?appId=${props.appId}&optionNumber=${form.value.optionNumber}&questionNumber=${form.value.questionNumber}`, 
    { withCredentials: true }
  );
  disabled.value = true;
  sseSubmitting.value = true;
  // 接收信息
  eventSource.onmessage = (event) => {
    console.log(event.data);
    props.onSSESuccess?.(JSON.parse(event.data));
  };
  // 报错或连接关闭触发
  eventSource.onerror = (event) => {
    if (event.eventPhase === EventSource.CLOSED) {
      console.log("连接已关闭");
      props.onSSEClose?.(event);
      sseSubmitting.value = false;
      eventSource.close();
      handleCancel();
      disabled.value = false;
    }
  };
  // 连接打开触发
  eventSource.onopen = (event) => {
    console.log("连接建立");
    props.onSSEOpen?.(event);
  };
};
</script>
