import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "adminUserPage" }

import { watchEffect } from "vue";
  import { ref } from "vue";
  import API from "@/axios/api";
  import message from "@arco-design/web-vue/es/message";
  import { dayjs } from "@arco-design/web-vue/es/_utils/date";
  import { APP_SCORING_STRATEGY_MAP, APP_TYPE_MAP } from "@/constant/appEnum";
  
  import {
    deleteUserAnswerUsingPost,
    listMyUserAnswerVoByPageUsingPost,
    listUserAnswerByPageUsingPost,
  } from "@/axios/api/userAnswerController";
import { listMyAppVoByPageUsingPost } from "@/axios/api/appController";
  
export default /*@__PURE__*/_defineComponent({
  __name: 'MyAnswerPage',
  setup(__props) {

  const initSearchParams = {
    current: 1,
    pageSize: 10,
  };
  const searchParams = ref<API.UserAnswerQueryRequest>({
    ...initSearchParams,
  });
  const formSearchParams = ref<API.UserAnswerQueryRequest>({});
  const dataList = ref<API.UserAnswerVO[]>();
  const total = ref<number>(0);
  const loadData = async () => {
    const res = await listMyUserAnswerVoByPageUsingPost(searchParams.value);
    if (res.data.code === 0) {
      dataList.value = res.data.data?.records || [];
      total.value = res.data.data?.total || 0;
    } else {
      message.error("获取数据失败：" + res.data.message);
    }
  };
  const onPageChange = (page: number) => {
    searchParams.value = {
      ...searchParams.value,
      current: page,
    };
  };
  const doDelete = async (record: API.User) => {
    if (!record.id) return;
    const res = await deleteUserAnswerUsingPost({
      id: record.id,
    });
    if (res.data.code === 0) {
      loadData();
    } else {
      message.error("删除失败：" + res.data.message);
    }
  };
  const doSearch = () => {
    searchParams.value = {
      ...initSearchParams,
      ...formSearchParams.value,
    };
  };
  watchEffect(() => {
    loadData();
  });
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "选项",
      dataIndex: "choices",
    },
    {
      title: "结果ID",
      dataIndex: "resultId",
    },
    {
      title: "名称",
      dataIndex: "resultName",
    },
    {
      title: "描述",
      dataIndex: "resultDesc",
    },
    {
      title: "图片",
      dataIndex: "resultPicture",
      slotName: "resultPicture",
    },
    {
      title: "得分",
      dataIndex: "resultScore",
    },
    {
      title: "应用ID",
      dataIndex: "appId",
    },
    {
      title: "应用类型",
      dataIndex: "appType",
      slotName: "appType",
    },
    {
      title: "评分策略",
      dataIndex: "scoringStrategy",
      slotName: "scoringStrategy",
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      slotName: "createTime",
    },
    {
      title: "操作",
      slotName: "optional",
    },
  ];
  
return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_form, {
      model: searchParams.value,
      style: { marginBottom: '20px' },
      "label-align": "left",
      layout: "inline",
      "auto-label-width": "",
      onSubmit: doSearch
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, {
          field: "resultName",
          label: "结果名称"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              "allow-clear": "",
              modelValue: formSearchParams.value.resultName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formSearchParams.value.resultName) = $event)),
              placeholder: "请输入结果名称"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          field: "resultDesc",
          label: "结果描述"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              "allow-clear": "",
              modelValue: formSearchParams.value.resultDesc,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((formSearchParams.value.resultDesc) = $event)),
              placeholder: "请输入结果描述"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          field: "appId",
          label: "应用ID"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              "allow-clear": "",
              modelValue: formSearchParams.value.appId as any,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((formSearchParams.value.appId as any) = $event)),
              placeholder: "请输入应用ID"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              "html-type": "submit",
              style: {"width":"120px"}
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("搜索")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"]),
    _createVNode(_component_a_table, {
      columns: columns,
      data: dataList.value,
      pagination: {
          showTotal: true,
          pageSize: searchParams.value.pageSize,
          current: searchParams.value.current,
          total: total.value,
        },
      onPageChange: onPageChange
    }, {
      resultPicture: _withCtx(({ record }) => [
        _createVNode(_component_a_image, {
          width: "32",
          src: record.resultPicture
        }, null, 8, ["src"])
      ]),
      appType: _withCtx(({ record }) => [
        _createTextVNode(_toDisplayString(_unref(APP_TYPE_MAP).get(record.appType)), 1)
      ]),
      scoringStrategy: _withCtx(({ record }) => [
        _createTextVNode(_toDisplayString(_unref(APP_SCORING_STRATEGY_MAP).get(record.scoringStrategy)), 1)
      ]),
      createTime: _withCtx(({ record }) => [
        _createElementVNode("td", null, _toDisplayString(_unref(dayjs)(record.createTime).format("YYYY-MM-DD HH:mm:ss")), 1)
      ]),
      updateTime: _withCtx(({ record }) => [
        _createElementVNode("td", null, _toDisplayString(_unref(dayjs)(record.updateTime).format("YYYY-MM-DD HH:mm:ss")), 1)
      ]),
      optional: _withCtx(({ record }) => [
        _createVNode(_component_a_space, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              status: "danger",
              onClick: ($event: any) => (doDelete(record))
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("删除")
              ])),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }, 8, ["data", "pagination"])
  ]))
}
}

})