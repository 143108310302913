import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "appStatisticPage" }
const _hoisted_2 = { class: "search_bar" }

import { computed, ref, watchEffect } from "vue";
import message from "@arco-design/web-vue/es/message";
import "echarts";
import VChart from "vue-echarts";
import { REVIEW_STATUS_ENUM } from "@/constant/app";
import { listAppVoByPageUsingPost } from "@/axios/api/appController";
import {
  doAppAnswerCountUsingGet,
  doAppAnswerResultCountUsingGet,
} from "@/axios/api/statisticController";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppStatisticPage',
  setup(__props) {

const appAnswerCountList = ref<API.AppAnswerCountDTO[]>([]);
const appAnswerResultCountList = ref<API.AppAnswerResultCountDTO[]>([]);
const appAnswerCountOptions = computed(() => {
  return {
    xAxis: {
      type: "category",
      data: appAnswerCountList.value.map((item) => item.appId),
      name: "应用 ID",
    },
    yAxis: {
      type: "value",
      name: "回答次数",
    },
    series: [
      {
        data: appAnswerCountList.value.map((item) => item.answerCount),
        type: "bar",
      },
    ],
  };
});
const appAnswerResultCountOptions = computed(() => {
  return {
    legend: {
      orient: "vertical",
      left: "left",
    },
    series: [
      {
        name: "应用答案结果分布",
        type: "pie",
        radius: "50%",
        data: appAnswerResultCountList.value.map((item) => {
          return { value: item.resultCount, name: item.resultName };
        }),
        // [ { value: 1048, name: "Search Engine" },]
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
});

const loadAppAnswerCountData = async () => {
  const res = await doAppAnswerCountUsingGet();
  if (res.data.code === 0) {
    appAnswerCountList.value = res.data.data || [];
  } else {
    message.error("获取数据失败：" + res.data.message);
  }
};
const loadAppAnswerResultCountData = async (appId: string) => {
  if (!appId) return;
  const res = await doAppAnswerResultCountUsingGet({
    appId: appId as any,
  });
  if (res.data.code === 0) {
    appAnswerResultCountList.value = res.data.data || [];
  } else {
    message.error("获取数据失败：" + res.data.message);
  }
};
/**
 * 参数改变时数据重新加载
 */
watchEffect(() => {
  loadAppAnswerCountData();
});
watchEffect(() => {
  loadAppAnswerResultCountData("");
});

return (_ctx: any,_cache: any) => {
  const _component_a_input_search = _resolveComponent("a-input-search")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("h2", null, "热门应用统计", -1)),
    _createVNode(_unref(VChart), {
      option: appAnswerCountOptions.value,
      style: {"height":"300px"}
    }, null, 8, ["option"]),
    _cache[2] || (_cache[2] = _createElementVNode("h2", null, "应用结果统计", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_input_search, {
        style: { width: '320px' },
        placeholder: "请输入AppId",
        "button-text": "搜索",
        size: "large",
        "search-button": "",
        onSearch: _cache[0] || (_cache[0] = (value) => loadAppAnswerResultCountData(value))
      })
    ]),
    _cache[3] || (_cache[3] = _createElementVNode("div", { style: {"margin-bottom":"16px"} }, null, -1)),
    _createVNode(_unref(VChart), {
      option: appAnswerResultCountOptions.value,
      style: {"height":"300px"}
    }, null, 8, ["option"])
  ]))
}
}

})