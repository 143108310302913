import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "addAppPage" }

import API from "@/axios/api";
import { userLoginUsingPost } from "@/axios/api/userController";
import { useLoginUserStore } from "@/store/userStore";
import { reactive, ref, watchEffect } from "vue";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";
import {
  addScoringResultUsingPost,
  editScoringResultUsingPost,
  getScoringResultVoByIdUsingGet,
} from "@/axios/api/scoringResultController";
import { APP_TYPE_MAP, APP_SCORING_STRATEGY_MAP } from "@/constant/appEnum";
import ScoringResultTable from "@/views/add/components/ScoringResultTable.vue";
// import PictureUploader from "@/components/PictureUploader.vue";

interface Props {
  appId: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AddScoringResultPage',
  props: {
    appId: { default: () => 0 }
  },
  setup(__props: any) {

const props = __props;
const form = ref({
  resultDesc: "",
  resultName: "",
  resultPicture: "",
} as API.ScoringResultAddRequest);
const oldScoringResult = ref<API.ScoringResultVO>();
const updateId = ref<number>();
const tableRef = ref();
const doUpdate = (scoringResult: API.ScoringResultVO) => {
  updateId.value = scoringResult.id;
  form.value = scoringResult;
};

const loginUserStore = useLoginUserStore();
const router = useRouter();
const handleSubmit = async () => {
  if (!props.appId) return;
  let res: any;
  if (updateId.value) {
    res = await editScoringResultUsingPost({
      id: updateId.value,
      ...form.value,
    });
  } else {
    res = await addScoringResultUsingPost({
      appId: props.appId,
      ...form.value,
    });
  }
  if (res.data.code === 0) {
    message.success("操作成功");
  } else {
    message.error("操作失败" + res.data.message);
  }
  if (tableRef.value) {
    tableRef.value.loadData();
    updateId.value = undefined;
    form.value = {
      resultDesc: "",
      resultName: "",
      resultPicture: "",
    };
  }
};

return (_ctx: any,_cache: any) => {
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_input_tag = _resolveComponent("a-input-tag")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[6] || (_cache[6] = _createElementVNode("h2", { style: {"margin-bottom":"32px"} }, "设置评分", -1)),
    _createVNode(_component_a_form, {
      model: form.value,
      style: { width: '480px' },
      "label-align": "left",
      "auto-label-width": "",
      onSubmit: handleSubmit
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, { label: "应用 ID" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.appId), 1)
          ]),
          _: 1
        }),
        (updateId.value)
          ? (_openBlock(), _createBlock(_component_a_form_item, {
              key: 0,
              label: "修改评分 ID"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(updateId.value), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_a_form_item, {
          field: "resultName",
          label: "结果名称"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              modelValue: form.value.resultName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.resultName) = $event)),
              placeholder: "请输入结果名称"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          field: "resultDesc",
          label: "结果描述"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              modelValue: form.value.resultDesc,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.value.resultDesc) = $event)),
              placeholder: "请输入结果描述"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          field: "resultPicture",
          label: "结果图标"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              modelValue: form.value.resultPicture,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((form.value.resultPicture) = $event)),
              placeholder: "请输入结果图标"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          field: "appType",
          label: "结果集"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input_tag, {
              modelValue: form.value.resultProp,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((form.value.resultProp) = $event)),
              style: { width: '320px' },
              placeholder: "请输入结果集, 按回车确认",
              "allow-clear": ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          field: "scoringStrategy",
          label: "结果得分范围"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input_number, {
              modelValue: form.value.resultScoreRange,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((form.value.resultScoreRange) = $event)),
              style: { width: '320px' },
              placeholder: "请输入结果得分范围",
              class: "input-demo",
              min: 10,
              max: 100
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              "html-type": "submit",
              type: "primary",
              style: {"width":"120px"}
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode("提交")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"]),
    _cache[7] || (_cache[7] = _createElementVNode("h2", { style: {"margin-bottom":"32px"} }, "评分管理", -1)),
    _createVNode(ScoringResultTable, {
      appId: _ctx.appId,
      doUpdate: doUpdate,
      ref_key: "tableRef",
      ref: tableRef
    }, null, 8, ["appId"])
  ]))
}
}

})