<template>
  <div id="addAppPage">
    <h2 style="margin-bottom: 32px">设置评分</h2>
    <a-form
      :model="form"
      :style="{ width: '480px' }"
      label-align="left"
      auto-label-width
      @submit="handleSubmit"
    >
      <a-form-item label="应用 ID">
        {{ appId }}
      </a-form-item>
      <a-form-item v-if="updateId" label="修改评分 ID">
        {{ updateId }}
      </a-form-item>
      <a-form-item field="resultName" label="结果名称">
        <a-input v-model="form.resultName" placeholder="请输入结果名称" />
      </a-form-item>
      <a-form-item field="resultDesc" label="结果描述">
        <a-input v-model="form.resultDesc" placeholder="请输入结果描述" />
      </a-form-item>
      <a-form-item field="resultPicture" label="结果图标">
        <a-input v-model="form.resultPicture" placeholder="请输入结果图标" />
      </a-form-item>
      <a-form-item field="appType" label="结果集">
        <a-input-tag
          v-model="form.resultProp"
          :style="{ width: '320px' }"
          placeholder="请输入结果集, 按回车确认"
          allow-clear
        />
      </a-form-item>
      <a-form-item field="scoringStrategy" label="结果得分范围">
        <a-input-number
          v-model="form.resultScoreRange"
          :style="{ width: '320px' }"
          placeholder="请输入结果得分范围"
          class="input-demo"
          :min="10"
          :max="100"
        />
      </a-form-item>
      <a-form-item>
        <a-button html-type="submit" type="primary" style="width: 120px"
          >提交</a-button
        >
      </a-form-item>
    </a-form>
    <h2 style="margin-bottom: 32px">评分管理</h2>
    <ScoringResultTable :appId="appId" :doUpdate="doUpdate" ref="tableRef" />
  </div>
</template>

<script setup lang="ts">
import API from "@/axios/api";
import { userLoginUsingPost } from "@/axios/api/userController";
import { useLoginUserStore } from "@/store/userStore";
import { reactive, withDefaults, defineProps, ref, watchEffect } from "vue";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";
import {
  addScoringResultUsingPost,
  editScoringResultUsingPost,
  getScoringResultVoByIdUsingGet,
} from "@/axios/api/scoringResultController";
import { APP_TYPE_MAP, APP_SCORING_STRATEGY_MAP } from "@/constant/appEnum";
import ScoringResultTable from "@/views/add/components/ScoringResultTable.vue";
// import PictureUploader from "@/components/PictureUploader.vue";

interface Props {
  appId: number;
}

const props = withDefaults(defineProps<Props>(), {
  appId: () => 0,
});
const form = ref({
  resultDesc: "",
  resultName: "",
  resultPicture: "",
} as API.ScoringResultAddRequest);
const oldScoringResult = ref<API.ScoringResultVO>();
const updateId = ref<number>();
const tableRef = ref();
const doUpdate = (scoringResult: API.ScoringResultVO) => {
  updateId.value = scoringResult.id;
  form.value = scoringResult;
};

const loginUserStore = useLoginUserStore();
const router = useRouter();
const handleSubmit = async () => {
  if (!props.appId) return;
  let res: any;
  if (updateId.value) {
    res = await editScoringResultUsingPost({
      id: updateId.value,
      ...form.value,
    });
  } else {
    res = await addScoringResultUsingPost({
      appId: props.appId,
      ...form.value,
    });
  }
  if (res.data.code === 0) {
    message.success("操作成功");
  } else {
    message.error("操作失败" + res.data.message);
  }
  if (tableRef.value) {
    tableRef.value.loadData();
    updateId.value = undefined;
    form.value = {
      resultDesc: "",
      resultName: "",
      resultPicture: "",
    };
  }
};
</script>
