import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {
          height: '204px',
          overflow: 'hidden',
        } }
const _hoisted_2 = ["alt", "src"]
const _hoisted_3 = { style: { display: 'flex', alignItems: 'center', color: '#1D2129' } }

import {
  IconThumbUp,
  IconShareInternal,
  IconMore,
} from "@arco-design/web-vue/es/icon";
import API from "@/axios/api";
import { ref } from "vue";
import { useRouter } from "vue-router";
import ShareModal from "@/components/ShareModal.vue";
interface Props {
  app: API.AppVO;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'AppCard',
  props: {
    app: { default: () => ({}) }
  },
  setup(__props: any) {

const props = __props;
const router = useRouter();
const doCardClick = () => {
  router.push(`/app/detail/${props.app.id}`);
};
// 分享
const shareLink = ref(
  `${window.location.protocol}//${window.location.host}/app/detail/${props.app.id}`
);
const ShareModalRef = ref();
const doShare = (e: Event) => {
  if (ShareModalRef.value) {
    ShareModalRef.value.openModal();
  }
  // 阻止冒泡，防止跳转到详情页
  e.stopPropagation();
};

return (_ctx: any,_cache: any) => {
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_card_meta = _resolveComponent("a-card-meta")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_card, {
      class: "appCard",
      hoverable: "",
      onClick: doCardClick
    }, {
      actions: _withCtx(() => [
        _createElementVNode("span", {
          class: "icon-hover",
          onClick: doShare
        }, [
          _createVNode(_unref(IconShareInternal))
        ])
      ]),
      cover: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("img", {
            style: { width: '100%', transform: 'translateY(-20px)' },
            alt: _ctx.app.appName,
            src: _ctx.app.appIcon
          }, null, 8, _hoisted_2)
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_card_meta, {
          title: _ctx.app.appName,
          description: _ctx.app.appDesc
        }, {
          avatar: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              (!_ctx.app.user?.userAvatar || _ctx.app.user?.userAvatar == '')
                ? (_openBlock(), _createBlock(_component_a_avatar, {
                    key: 0,
                    "icon:size": "24",
                    style: { marginRight: '8px' }
                  }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode(" A ")
                    ])),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_a_avatar, {
                    key: 1,
                    "image-url": _ctx.app.user?.userAvatar,
                    size: 24,
                    style: { marginRight: '8px' }
                  }, null, 8, ["image-url"])),
              _createVNode(_component_a_typography_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.app.user?.userName ?? "无名"), 1)
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }, 8, ["title", "description"])
      ]),
      _: 1
    }),
    _createVNode(ShareModal, {
      link: shareLink.value,
      title: "应用分享",
      ref_key: "ShareModalRef",
      ref: ShareModalRef
    }, null, 8, ["link"])
  ], 64))
}
}

})