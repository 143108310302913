import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "adminAppPage" }

import { watchEffect } from "vue";
import { ref } from "vue";
import API from "@/axios/api";
import message from "@arco-design/web-vue/es/message";
import { dayjs } from "@arco-design/web-vue/es/_utils/date";
import {
  REVIEW_STATUS_ENUM,
  APP_TYPE_MAP,
  APP_SCORING_STRATEGY_MAP,
  REVIEW_STATUS_MAP,
} from "@/constant/app";
import {
  deleteAppUsingPost,
  doAppReviewUsingPost,
  listAppByPageUsingPost,
} from "@/axios/api/appController";

export default /*@__PURE__*/_defineComponent({
  __name: 'AdminAppPage',
  setup(__props) {

const doReview = async (
  record: API.App,
  reviewStatus: number,
  reviewMessage?: string
) => {
  if (!record.id) return;
  const res = await doAppReviewUsingPost({
    id: record.id,
    reviewStatus,
    reviewMessage,
  });
  if (res.data.code === 0) {
    loadData();
  } else {
    message.error("审核失败：" + res.data.message);
  }
};
const initSearchParams = {
  current: 1,
  pageSize: 10,
};
const searchParams = ref<API.AppQueryRequest>({
  ...initSearchParams,
});
const formSearchParams = ref<API.AppQueryRequest>({});
const dataList = ref<API.App[]>();
const total = ref<number>(0);
const loadData = async () => {
  const res = await listAppByPageUsingPost(searchParams.value);
  if (res.data.code === 0) {
    dataList.value = res.data.data?.records || [];
    total.value = res.data.data?.total || 0;
  } else {
    message.error("获取数据失败：" + res.data.message);
  }
};
const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};
const doDelete = async (record: API.App) => {
  if (!record.id) return;
  const res = await deleteAppUsingPost({
    id: record.id,
  });
  if (res.data.code === 0) {
    loadData();
  } else {
    message.error("删除失败：" + res.data.message);
  }
};
const doSearch = () => {
  searchParams.value = {
    ...initSearchParams,
    ...formSearchParams.value,
  };
};
/**
 * 监听searchParams变化，改变时触发数据的重新加载
 */
watchEffect(() => {
  loadData();
});
const columns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "应用名",
    dataIndex: "appName",
  },
  {
    title: "应用描述",
    dataIndex: "appDesc",
  },
  {
    title: "应用图标",
    dataIndex: "appIcon",
    slotName: "appIcon",
  },
  {
    title: "应用类型",
    dataIndex: "appType",
    slotName: "appType",
  },
  {
    title: "评分策略",
    dataIndex: "scoringStrategy",
    slotName: "scoringStrategy",
  },
  {
    title: "审核状态",
    dataIndex: "reviewStatus",
    slotName: "reviewStatus",
  },
  {
    title: "审核信息",
    dataIndex: "reviewMessage",
  },
  {
    title: "审核时间",
    dataIndex: "reviewTime",
    slotName: "reviewTime",
  },
  {
    title: "审核人ID",
    dataIndex: "reviewerId",
  },
  {
    title: "用户ID",
    dataIndex: "userId",
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    slotName: "createTime",
  },
  {
    title: "更新时间",
    dataIndex: "updateTime",
    slotName: "updateTime",
  },
  {
    title: "操作",
    slotName: "optional",
  },
];

return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_form, {
      model: searchParams.value,
      style: { marginBottom: '20px' },
      "label-align": "left",
      layout: "inline",
      "auto-label-width": "",
      onSubmit: doSearch
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, {
          field: "appName",
          label: "应用名称"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              "allow-clear": "",
              modelValue: formSearchParams.value.appName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formSearchParams.value.appName) = $event)),
              placeholder: "请输入应用名称"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          field: "appDesc",
          label: "应用描述"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              "allow-clear": "",
              modelValue: formSearchParams.value.appDesc,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((formSearchParams.value.appDesc) = $event)),
              placeholder: "请输入应用描述"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              "html-type": "submit",
              style: {"width":"120px"}
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("搜索")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"]),
    _createVNode(_component_a_table, {
      columns: columns,
      data: dataList.value,
      pagination: {
        showTotal: true,
        pageSize: searchParams.value.pageSize,
        current: searchParams.value.current,
        total: total.value,
      },
      onPageChange: onPageChange
    }, {
      appIcon: _withCtx(({ record }) => [
        _createVNode(_component_a_image, {
          width: "64",
          src: record.appIcon
        }, null, 8, ["src"])
      ]),
      appType: _withCtx(({ record }) => [
        _createTextVNode(_toDisplayString(_unref(APP_TYPE_MAP)[record.appType]), 1)
      ]),
      scoringStrategy: _withCtx(({ record }) => [
        _createTextVNode(_toDisplayString(_unref(APP_SCORING_STRATEGY_MAP)[record.scoringStrategy]), 1)
      ]),
      reviewStatus: _withCtx(({ record }) => [
        _createTextVNode(_toDisplayString(_unref(REVIEW_STATUS_MAP)[record.reviewStatus]), 1)
      ]),
      reviewTime: _withCtx(({ record }) => [
        _createElementVNode("td", null, _toDisplayString(record.reviewTime &&
            _unref(dayjs)(record.reviewTime).format("YYYY-MM-DD HH:mm:ss")), 1)
      ]),
      createTime: _withCtx(({ record }) => [
        _createElementVNode("td", null, _toDisplayString(_unref(dayjs)(record.createTime).format("YYYY-MM-DD HH:mm:ss")), 1)
      ]),
      updateTime: _withCtx(({ record }) => [
        _createElementVNode("td", null, _toDisplayString(_unref(dayjs)(record.updateTime).format("YYYY-MM-DD HH:mm:ss")), 1)
      ]),
      optional: _withCtx(({ record }) => [
        _createVNode(_component_a_space, null, {
          default: _withCtx(() => [
            (record.reviewStatus !== _unref(REVIEW_STATUS_ENUM).PASS)
              ? (_openBlock(), _createBlock(_component_a_button, {
                  key: 0,
                  status: "success",
                  onClick: ($event: any) => (doReview(record, _unref(REVIEW_STATUS_ENUM).PASS, ''))
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("通过")
                  ])),
                  _: 2
                }, 1032, ["onClick"]))
              : _createCommentVNode("", true),
            (record.reviewStatus !== _unref(REVIEW_STATUS_ENUM).REJECT)
              ? (_openBlock(), _createBlock(_component_a_button, {
                  key: 1,
                  status: "warning",
                  onClick: ($event: any) => (
              doReview(record, _unref(REVIEW_STATUS_ENUM).REJECT, '不符合上架要求')
            )
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode("拒绝")
                  ])),
                  _: 2
                }, 1032, ["onClick"]))
              : _createCommentVNode("", true),
            _createVNode(_component_a_button, {
              status: "danger",
              onClick: ($event: any) => (doDelete(record))
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode("删除")
              ])),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }, 8, ["data", "pagination"])
  ]))
}
}

})