import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "homePage" }
const _hoisted_2 = { class: "searchBar" }

import { ref, watchEffect } from "vue";
import message from "@arco-design/web-vue/es/message";
import AppCard from "@/components/AppCard.vue";
import { REVIEW_STATUS_ENUM } from "@/constant/app";
import { listAppVoByPageUsingPost } from "@/axios/api/appController";


export default /*@__PURE__*/_defineComponent({
  __name: 'HomePage',
  setup(__props) {

const initSearchParams = {
  current: 1,
  pageSize: 12,
};
const searchParams = ref<API.AppQueryRequest>({
  ...initSearchParams,
});
const formSearchParams = ref<API.AppQueryRequest>({});
const dataList = ref<API.AppVO[]>();
const total = ref<number>(0);
const loadData = async () => {
  const params = {
    reviewStatus: REVIEW_STATUS_ENUM.PASS,
    ...searchParams.value,
  };
  const res = await listAppVoByPageUsingPost(params);
  if (res.data.code === 0) {
    dataList.value = res.data.data?.records || [];
    total.value = res.data.data?.total || 0;
  } else {
    message.error("获取数据失败：" + res.data.message);
  }
};
const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};
const doSearch = () => {
  searchParams.value = {
    ...initSearchParams,
    ...formSearchParams.value,
  };
};
watchEffect(() => {
  loadData();
});

return (_ctx: any,_cache: any) => {
  const _component_a_input_search = _resolveComponent("a-input-search")!
  const _component_a_list = _resolveComponent("a-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_input_search, {
        style: { width: '320px' },
        placeholder: "快速发现答题应用",
        "button-text": "搜索",
        size: "large",
        "search-button": ""
      })
    ]),
    _createVNode(_component_a_list, {
      class: "list-demo-action-layout",
      "grid-props": { gutter: [20, 20], sm: 24, md: 12, lg: 8, xl: 6 },
      bordered: false,
      data: dataList.value,
      "pagination-props": {
        pageSize: searchParams.value.pageSize,
        current: searchParams.value.current,
        total: total.value,
      }
    }, {
      item: _withCtx(({ item }) => [
        _createVNode(AppCard, { app: item }, null, 8, ["app"])
      ]),
      _: 1
    }, 8, ["data", "pagination-props"])
  ]))
}
}

})