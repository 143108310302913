import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "doAnswerPage" }
const _hoisted_2 = { style: {"margin-bottom":"32px"} }
const _hoisted_3 = { style: {"margin-top":"24px"} }

import API from "@/axios/api";
import {
  computed,
  ref,
  watchEffect,
  reactive,
} from "vue";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";
import { getAppVoByIdUsingGet } from "@/axios/api/appController";
import { listQuestionVoByPageUsingPost } from "@/axios/api/questionController";
import { addUserAnswerUsingPost, generateUserAnswerIdUsingGet } from "@/axios/api/userAnswerController";
// import PictureUploader from "@/components/PictureUploader.vue";

interface Props {
  appId: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DoAnswerPage',
  props: {
    appId: { default: () => 0 }
  },
  setup(__props: any) {

const props = __props;
const submitting = ref(false);

// 唯一 id
const id = ref<number>();
// 生成唯一 id
const generateId = async() => {
  const res = await generateUserAnswerIdUsingGet();
  if (res.data.code === 0) {
    id.value = res.data.data as any;
  } else {
    message.error("获取唯一Id失败：" + res.data.message);
  }
}
// 进入页面时，生成唯一id 
// js存在精度丢失的问题：在后端已经配置了Long转json
watchEffect(() => {
  generateId();
})
// 题目内容结构
const questionContent = ref<API.QuestionContentDTO[]>([]);
const app = ref<API.AppVO>({});
// 1. 当前题目的序号（从1开始）
const current = ref(1);
// 当前题目
const currentQuestion = ref<API.QuestionContentDTO>({});
// 当前题目的选项 ：computed : 一开始没有获取到题目，获取到题目时会重新计算
const questionOptions = computed(() => {
  return currentQuestion.value?.options
    ? currentQuestion.value?.options.map((option: any) => {
        return {
          label: `${option.key}. ${option.value}`,
          value: option.key,
        };
      })
    : [];
});
// 当前回答
const currentAnswer = ref<string>("");
// 回答列表
const answerList = reactive<string[]>([]);
/**
 * 加载数据
 */
const loadData = async () => {
  if (!props.appId) return;
  // 获取app
  let res: any = await getAppVoByIdUsingGet({ id: props.appId });
  if (res.data.code === 0) {
    app.value = res.data.data;
  } else {
    message.error("获取应用失败：" + res.data.message);
  }
  // 获取题目
  res = await listQuestionVoByPageUsingPost({
    appId: props.appId,
    current: 1,
    pageSize: 1,
    sortField: "createTime",
    sortOrder: "descend",
  });
  if (res.data.code === 0 && res.data.data?.records) {
    questionContent.value = res.data.data?.records[0]?.questionContent || [];
  } else {
    message.error("获取题目失败：" + res.data.message);
  }
};
watchEffect(() => {
  loadData();
});
watchEffect(() => {
  currentQuestion.value = questionContent.value[current.value - 1];
  currentAnswer.value = answerList[current.value - 1];
});
const router = useRouter();
const handleSubmit = async () => {
  if (!props.appId || !answerList) return;
  submitting.value = true;
  const res = await addUserAnswerUsingPost({
    appId: props.appId,
    choices: answerList,
    id: id.value,
  });
  if (res.data.code === 0 && res.data.data) {
    router.push(`/answer/result/${res.data.data}`);
  } else {
    message.error("提交答案失败" + res.data.message);
  }
  submitting.value = false;
};
/**
 * 选中选项后，保存选项记录
 * @param value
 */
const handleRadioChange = (value: any) => {
  answerList[current.value - 1] = value;
};

return (_ctx: any,_cache: any) => {
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_card, null, {
      default: _withCtx(() => [
        _createElementVNode("h1", null, _toDisplayString(app.value.appName), 1),
        _createElementVNode("p", null, _toDisplayString(app.value.appDesc), 1),
        _createElementVNode("h2", _hoisted_2, _toDisplayString(current.value + "、" + currentQuestion.value?.title), 1),
        _createElementVNode("div", null, [
          _createVNode(_component_a_radio_group, {
            modelValue: currentAnswer.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((currentAnswer).value = $event)),
            options: questionOptions.value,
            direction: "vertical",
            onChange: handleRadioChange
          }, null, 8, ["modelValue", "options"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_a_space, { size: "large" }, {
            default: _withCtx(() => [
              (current.value < questionContent.value.length)
                ? (_openBlock(), _createBlock(_component_a_button, {
                    key: 0,
                    type: "primary",
                    disabled: !currentAnswer.value,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (current.value += 1))
                  }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode(" 下一题 ")
                    ])),
                    _: 1
                  }, 8, ["disabled"]))
                : _createCommentVNode("", true),
              (current.value === questionContent.value.length)
                ? (_openBlock(), _createBlock(_component_a_button, {
                    key: 1,
                    type: "primary",
                    shape: "round",
                    disabled: !currentAnswer.value,
                    onClick: handleSubmit,
                    loading: submitting.value
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(submitting.value ? "提交中" : "查看结果"), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "loading"]))
                : _createCommentVNode("", true),
              (current.value > 1)
                ? (_openBlock(), _createBlock(_component_a_button, {
                    key: 2,
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (current.value--))
                  }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode(" 上一题 ")
                    ])),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    })
  ]))
}
}

})