<template>
  <div id="adminAppPage">
    <a-form
      :model="searchParams"
      :style="{ marginBottom: '20px' }"
      label-align="left"
      layout="inline"
      auto-label-width
      @submit="doSearch"
    >
      <a-form-item field="appName" label="应用名称">
        <a-input
          allow-clear
          v-model="formSearchParams.appName"
          placeholder="请输入应用名称"
        />
      </a-form-item>
      <a-form-item field="appDesc" label="应用描述">
        <a-input
          allow-clear
          v-model="formSearchParams.appDesc"
          placeholder="请输入应用描述"
        />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="submit" style="width: 120px"
          >搜索</a-button
        >
      </a-form-item>
    </a-form>
    <a-table
      :columns="columns"
      :data="dataList"
      :pagination="{
        showTotal: true,
        pageSize: searchParams.pageSize,
        current: searchParams.current,
        total,
      }"
      @page-change="onPageChange"
    >
      <template #appIcon="{ record }">
        <a-image width="64" :src="record.appIcon" />
      </template>
      <template #appType="{ record }">
        {{ APP_TYPE_MAP[record.appType] }}
      </template>
      <template #scoringStrategy="{ record }">
        {{ APP_SCORING_STRATEGY_MAP[record.scoringStrategy] }}
      </template>
      <template #reviewStatus="{ record }">
        {{ REVIEW_STATUS_MAP[record.reviewStatus] }}
      </template>
      <template #reviewTime="{ record }">
        <td>
          {{
            record.reviewTime &&
            dayjs(record.reviewTime).format("YYYY-MM-DD HH:mm:ss")
          }}
        </td>
      </template>
      <template #createTime="{ record }">
        <td>
          {{ dayjs(record.createTime).format("YYYY-MM-DD HH:mm:ss") }}
        </td>
      </template>
      <template #updateTime="{ record }">
        <td>
          {{ dayjs(record.updateTime).format("YYYY-MM-DD HH:mm:ss") }}
        </td>
      </template>
      <template #optional="{ record }">
        <a-space>
          <a-button
            v-if="record.reviewStatus !== REVIEW_STATUS_ENUM.PASS"
            status="success"
            @click="doReview(record, REVIEW_STATUS_ENUM.PASS, '')"
            >通过</a-button
          >
          <a-button
            v-if="record.reviewStatus !== REVIEW_STATUS_ENUM.REJECT"
            status="warning"
            @click="
              doReview(record, REVIEW_STATUS_ENUM.REJECT, '不符合上架要求')
            "
            >拒绝</a-button
          >
          <a-button status="danger" @click="doDelete(record)">删除</a-button>
        </a-space>
      </template>
    </a-table>
  </div>
</template>

<script setup lang="ts">
import { watchEffect } from "vue";
import { ref } from "vue";
import API from "@/axios/api";
import message from "@arco-design/web-vue/es/message";
import { dayjs } from "@arco-design/web-vue/es/_utils/date";
import {
  REVIEW_STATUS_ENUM,
  APP_TYPE_MAP,
  APP_SCORING_STRATEGY_MAP,
  REVIEW_STATUS_MAP,
} from "@/constant/app";
import {
  deleteAppUsingPost,
  doAppReviewUsingPost,
  listAppByPageUsingPost,
} from "@/axios/api/appController";
const doReview = async (
  record: API.App,
  reviewStatus: number,
  reviewMessage?: string
) => {
  if (!record.id) return;
  const res = await doAppReviewUsingPost({
    id: record.id,
    reviewStatus,
    reviewMessage,
  });
  if (res.data.code === 0) {
    loadData();
  } else {
    message.error("审核失败：" + res.data.message);
  }
};
const initSearchParams = {
  current: 1,
  pageSize: 10,
};
const searchParams = ref<API.AppQueryRequest>({
  ...initSearchParams,
});
const formSearchParams = ref<API.AppQueryRequest>({});
const dataList = ref<API.App[]>();
const total = ref<number>(0);
const loadData = async () => {
  const res = await listAppByPageUsingPost(searchParams.value);
  if (res.data.code === 0) {
    dataList.value = res.data.data?.records || [];
    total.value = res.data.data?.total || 0;
  } else {
    message.error("获取数据失败：" + res.data.message);
  }
};
const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};
const doDelete = async (record: API.App) => {
  if (!record.id) return;
  const res = await deleteAppUsingPost({
    id: record.id,
  });
  if (res.data.code === 0) {
    loadData();
  } else {
    message.error("删除失败：" + res.data.message);
  }
};
const doSearch = () => {
  searchParams.value = {
    ...initSearchParams,
    ...formSearchParams.value,
  };
};
/**
 * 监听searchParams变化，改变时触发数据的重新加载
 */
watchEffect(() => {
  loadData();
});
const columns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "应用名",
    dataIndex: "appName",
  },
  {
    title: "应用描述",
    dataIndex: "appDesc",
  },
  {
    title: "应用图标",
    dataIndex: "appIcon",
    slotName: "appIcon",
  },
  {
    title: "应用类型",
    dataIndex: "appType",
    slotName: "appType",
  },
  {
    title: "评分策略",
    dataIndex: "scoringStrategy",
    slotName: "scoringStrategy",
  },
  {
    title: "审核状态",
    dataIndex: "reviewStatus",
    slotName: "reviewStatus",
  },
  {
    title: "审核信息",
    dataIndex: "reviewMessage",
  },
  {
    title: "审核时间",
    dataIndex: "reviewTime",
    slotName: "reviewTime",
  },
  {
    title: "审核人ID",
    dataIndex: "reviewerId",
  },
  {
    title: "用户ID",
    dataIndex: "userId",
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    slotName: "createTime",
  },
  {
    title: "更新时间",
    dataIndex: "updateTime",
    slotName: "updateTime",
  },
  {
    title: "操作",
    slotName: "optional",
  },
];
</script>

<style scoped></style>
