<template>
  <div id="userLayout">
    <a-layout style="height: 100vh">
      <a-layout-header class="header">
        <a-space>
          <img src="@/assets/logo.png" class="logo" alt="logo" width="100" />
          <div>R嗒嗒 - AI答题平台</div>
        </a-space>
      </a-layout-header>
      <a-layout-content class="content">
        <router-view />
      </a-layout-content>
      <a-layout-footer class="footer">
        <a href="http://resume.richardzhenglab.top" target="_blank">
          菜鸟代码搬运工 - BY RICHARD
        </a>
      </a-layout-footer>
    </a-layout>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
#userLayout {
  text-align: center;
  background: url("https://mdn.alipayobjects.com/huamei_gcee1x/afts/img/A*y0ZTS6WLwvgAAAAAAAAAAAAADml6AQ/fmt.webp")
    0% 0% no-repeat;
}
#userLayout .header {
  margin-top: 1rem;
  font-size: 1.5rem;
}
#userLayout .header .logo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
#userLayout .content {
  margin: 1.4rem;
  padding: 1.25rem;
}
.footer {
  padding: 1rem;
  text-align: center;
  background-color: #efefef;
}
.footer a {
  color: #464646;
  text-decoration: none;
}
.footer a:hover {
  color: #222333;
  transition: all 0.3s ease-in-out;
}
</style>
