import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import API from "@/axios/api";
import { userLoginUsingPost } from "@/axios/api/userController";
import { useLoginUserStore } from "@/store/userStore";
import {
  reactive,
  ref,
  watchEffect,
} from "vue";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";
import { aiGenerateQuestionUsingPost } from "@/axios/api/questionController";
import { APP_TYPE_MAP, APP_SCORING_STRATEGY_MAP } from "@/constant/appEnum";
// import PictureUploader from "@/components/PictureUploader.vue";

interface Props {
  appId: number;
  onSuccess?: (result: API.QuestionContentDTO[]) => void;
  onSSESuccess?: (result: API.QuestionContentDTO[]) => void;
  onSSEOpen?: (event: any) => void;
  onSSEClose?: (event: any) => void;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'AiGenerateQuestionDrawer',
  props: {
    appId: { default: () => 0 },
    onSuccess: {},
    onSSESuccess: {},
    onSSEOpen: {},
    onSSEClose: {}
  },
  emits: ["success"],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;
const props = __props;

const form = ref({
  optionNumber: 2,
  questionNumber: 10,
} as API.AiGenerateQuestionRequest);
const visible = ref(false);
const submitting = ref(false);
const disabled = ref(false);
const sseSubmitting = ref(false);

const handleClick = () => {
  visible.value = true;
};
const handleOk = () => {
  visible.value = false;
};
const handleCancel = () => {
  visible.value = false;
};

/**
 * 提交
 */
const handleSubmit = async (data: any) => {
  if (!props.appId) return;

  submitting.value = true;
  const res = await aiGenerateQuestionUsingPost({
    appId: props.appId,
    ...form.value,
  });

  // @ts-ignore
  if (res.data.code === 0 && res.data.data.length > 0) {
    // 1. 使用props 传递参数（function）
    // if (props.onSuccess) {
    //   props.onSuccess(res.data.data);
    // }
    // 2. emit 绑定事件，传递参数
    emit("success", res.data.data);
    message.success("生成题目成功");
    handleCancel();
  } else {
    message.error("操作失败" + res.data.message);
  }
  submitting.value = false;
};
/**
 * 提交(实时生成)
 */
const handleSSESubmit = async () => {
  if (!props.appId) return;

  // TODO: 手动编写完整的后端地址
  const eventSource = new EventSource(
    "http://localhost:8101/api/question/ai_generate/sse" +
      `?appId=${props.appId}&optionNumber=${form.value.optionNumber}&questionNumber=${form.value.questionNumber}`, 
    { withCredentials: true }
  );
  disabled.value = true;
  sseSubmitting.value = true;
  // 接收信息
  eventSource.onmessage = (event) => {
    console.log(event.data);
    props.onSSESuccess?.(JSON.parse(event.data));
  };
  // 报错或连接关闭触发
  eventSource.onerror = (event) => {
    if (event.eventPhase === EventSource.CLOSED) {
      console.log("连接已关闭");
      props.onSSEClose?.(event);
      sseSubmitting.value = false;
      eventSource.close();
      handleCancel();
      disabled.value = false;
    }
  };
  // 连接打开触发
  eventSource.onopen = (event) => {
    console.log("连接建立");
    props.onSSEOpen?.(event);
  };
};

return (_ctx: any,_cache: any) => {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_drawer = _resolveComponent("a-drawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_button, {
      type: "outline",
      onClick: handleClick
    }, {
      default: _withCtx(() => _cache[2] || (_cache[2] = [
        _createTextVNode("AI 生成题目")
      ])),
      _: 1
    }),
    _createVNode(_component_a_drawer, {
      width: 340,
      visible: visible.value,
      onOk: handleOk,
      onCancel: handleCancel,
      unmountOnClose: ""
    }, {
      title: _withCtx(() => _cache[3] || (_cache[3] = [
        _createTextVNode(" AI 生成题目 ")
      ])),
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_a_form, {
            model: form.value,
            "label-align": "left",
            "auto-label-width": "",
            onSubmit: handleSubmit
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, { label: "应用 ID" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.appId), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                field: "questionNumber",
                label: "题目数量"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input_number, {
                    modelValue: form.value.questionNumber,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.questionNumber) = $event)),
                    placeholder: "请输入题目数量"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                field: "optionNumber",
                label: "选项数量"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input_number, {
                    modelValue: form.value.optionNumber,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.value.optionNumber) = $event)),
                    placeholder: "请输入选项数量"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_space, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        loading: submitting.value,
                        "html-type": "submit",
                        type: "primary",
                        style: {"width":"120px"},
                        disabled: disabled.value
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(submitting.value ? "生成中" : "一键生成"), 1)
                        ]),
                        _: 1
                      }, 8, ["loading", "disabled"]),
                      _createVNode(_component_a_button, {
                        loading: sseSubmitting.value,
                        style: {"width":"120px"},
                        onClick: handleSSESubmit
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(sseSubmitting.value ? "生成中" : "实时生成"), 1)
                        ]),
                        _: 1
                      }, 8, ["loading"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model"])
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
}

})