<template>
    <div id="adminUserPage">
      <a-form
        :model="searchParams"
        :style="{ marginBottom: '20px' }"
        label-align="left"
        layout="inline"
        auto-label-width
        @submit="doSearch"
      >
        <a-form-item field="resultName" label="结果名称">
          <a-input
            allow-clear
            v-model="formSearchParams.resultName"
            placeholder="请输入结果名称"
          />
        </a-form-item>
        <a-form-item field="resultDesc" label="结果描述">
          <a-input
            allow-clear
            v-model="formSearchParams.resultDesc"
            placeholder="请输入结果描述"
          />
        </a-form-item>
        <a-form-item field="appId" label="应用ID">
          <a-input
            allow-clear
            v-model="formSearchParams.appId as any"
            placeholder="请输入应用ID"
          />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" html-type="submit" style="width: 120px"
            >搜索</a-button
          >
        </a-form-item>
      </a-form>
      <a-table
        :columns="columns"
        :data="dataList"
        :pagination="{
          showTotal: true,
          pageSize: searchParams.pageSize,
          current: searchParams.current,
          total,
        }"
        @page-change="onPageChange"
      >
        <template #resultPicture="{ record }">
          <a-image width="32" :src="record.resultPicture" />
        </template>
        <template #appType="{ record }">
          {{ APP_TYPE_MAP.get(record.appType) }}
        </template>
        <template #scoringStrategy="{ record }">
          {{ APP_SCORING_STRATEGY_MAP.get(record.scoringStrategy) }}
        </template>
        <template #createTime="{ record }">
          <td>
            {{ dayjs(record.createTime).format("YYYY-MM-DD HH:mm:ss") }}
          </td>
        </template>
        <template #updateTime="{ record }">
          <td>
            {{ dayjs(record.updateTime).format("YYYY-MM-DD HH:mm:ss") }}
          </td>
        </template>
        <template #optional="{ record }">
          <a-space>
            <a-button status="danger" @click="doDelete(record)">删除</a-button>
          </a-space>
        </template>
      </a-table>
    </div>
  </template>
  
  <script setup lang="ts">
  import { watchEffect } from "vue";
  import { ref } from "vue";
  import API from "@/axios/api";
  import message from "@arco-design/web-vue/es/message";
  import { dayjs } from "@arco-design/web-vue/es/_utils/date";
  import { APP_SCORING_STRATEGY_MAP, APP_TYPE_MAP } from "@/constant/appEnum";
  
  import {
    deleteUserAnswerUsingPost,
    listMyUserAnswerVoByPageUsingPost,
    listUserAnswerByPageUsingPost,
  } from "@/axios/api/userAnswerController";
import { listMyAppVoByPageUsingPost } from "@/axios/api/appController";
  const initSearchParams = {
    current: 1,
    pageSize: 10,
  };
  const searchParams = ref<API.UserAnswerQueryRequest>({
    ...initSearchParams,
  });
  const formSearchParams = ref<API.UserAnswerQueryRequest>({});
  const dataList = ref<API.UserAnswerVO[]>();
  const total = ref<number>(0);
  const loadData = async () => {
    const res = await listMyUserAnswerVoByPageUsingPost(searchParams.value);
    if (res.data.code === 0) {
      dataList.value = res.data.data?.records || [];
      total.value = res.data.data?.total || 0;
    } else {
      message.error("获取数据失败：" + res.data.message);
    }
  };
  const onPageChange = (page: number) => {
    searchParams.value = {
      ...searchParams.value,
      current: page,
    };
  };
  const doDelete = async (record: API.User) => {
    if (!record.id) return;
    const res = await deleteUserAnswerUsingPost({
      id: record.id,
    });
    if (res.data.code === 0) {
      loadData();
    } else {
      message.error("删除失败：" + res.data.message);
    }
  };
  const doSearch = () => {
    searchParams.value = {
      ...initSearchParams,
      ...formSearchParams.value,
    };
  };
  watchEffect(() => {
    loadData();
  });
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "选项",
      dataIndex: "choices",
    },
    {
      title: "结果ID",
      dataIndex: "resultId",
    },
    {
      title: "名称",
      dataIndex: "resultName",
    },
    {
      title: "描述",
      dataIndex: "resultDesc",
    },
    {
      title: "图片",
      dataIndex: "resultPicture",
      slotName: "resultPicture",
    },
    {
      title: "得分",
      dataIndex: "resultScore",
    },
    {
      title: "应用ID",
      dataIndex: "appId",
    },
    {
      title: "应用类型",
      dataIndex: "appType",
      slotName: "appType",
    },
    {
      title: "评分策略",
      dataIndex: "scoringStrategy",
      slotName: "scoringStrategy",
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      slotName: "createTime",
    },
    {
      title: "操作",
      slotName: "optional",
    },
  ];
  </script>
  
  <style scoped></style>
  