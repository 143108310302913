<template>
  <div id="addQuestionPage">
    <h2 style="margin-bottom: 32px">设置题目</h2>
    <a-form
      :model="questionContent"
      :style="{ width: '480px' }"
      label-align="left"
      auto-label-width
      @submit="handleSubmit"
    >
      <a-form-item label="应用 ID">
        {{ appId }}
      </a-form-item>
      <!-- 遍历每道题目 -->
      <a-form-item label="题目列表" :content-flex="false" :merge-props="false">
        <a-space>
          <a-button @click="addQuestion(questionContent.length)"
            >底部添加题目</a-button
          >
          <!-- :onSuccess="onAiGenerateSuccess" -->
          <AiGenerateQuestionDrawer
            :appId="appId"
            @success="onAiGenerateSuccess"
            :onSSESuccess="onAiGenerateSuccessSSE"
            :onSSEClose="onSSEClose"
            :onSSEOpen="onSSEOpen"
          />
        </a-space>
        <div v-for="(question, index) in questionContent" :key="index">
          <a-card hoverable style="margin-bottom: 4px">
            <a-space>
              <h3>题目 {{ index + 1 }}</h3>
              <a-button @click="addQuestion(index + 1)">添加题目</a-button>
              <a-button status="danger" @click="deleteQuestion(index)"
                >删除题目</a-button
              >
            </a-space>

            <a-form-item
              field="question.title"
              :label="`题目 ${index + 1} 标题`"
            >
              <a-input v-model="question.title" placeholder="请输入题目" />
            </a-form-item>
            <!-- 题目选项 -->
            <a-space>
              <h4>题目{{ index + 1 }}选项列表</h4>
              <a-button
                @click="
                  addQuestionOption(question, (question.options ?? []).length)
                "
                >底部添加题目</a-button
              >
            </a-space>
            <a-form-item
              v-for="(option, optionIndex) in question.options"
              :key="optionIndex"
              :label="`选项${optionIndex + 1}`"
              :content-flex="false"
              :merge-props="false"
              style="border-bottom: 1px solid #ccc; padding-bottom: 16px"
            >
              <a-form-item label="选项 key">
                <a-input v-model="option.key" placeholder="请输入选项 key" />
              </a-form-item>
              <a-form-item label="选项值">
                <a-input v-model="option.value" placeholder="请输入选项值" />
              </a-form-item>
              <a-form-item label="选项结果">
                <a-input v-model="option.result" placeholder="请输入选项结果" />
              </a-form-item>
              <a-form-item label="选项得分">
                <a-input-number
                  v-model="option.score"
                  placeholder="请输入选项得分"
                />
              </a-form-item>
              <a-space size="large">
                <a-button
                  size="mini"
                  @click="addQuestionOption(question, optionIndex + 1)"
                  >添加选项</a-button
                >
                <a-button
                  size="mini"
                  status="danger"
                  @click="deleteQuestionOption(question, optionIndex)"
                  >删除选项</a-button
                >
              </a-space>
            </a-form-item>
            <!-- 题目选项END -->
          </a-card>
        </div>
      </a-form-item>
      <a-form-item>
        <a-button html-type="submit" type="primary" style="width: 120px"
          >提交</a-button
        >
      </a-form-item>
    </a-form>
  </div>
</template>

<script setup lang="ts">
import API from "@/axios/api";
import { userLoginUsingPost } from "@/axios/api/userController";
import { useLoginUserStore } from "@/store/userStore";
import { reactive, withDefaults, defineProps, ref, watchEffect } from "vue";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";
import {
  addAppUsingPost,
  editAppUsingPost,
  getAppVoByIdUsingGet,
} from "@/axios/api/appController";
import { APP_TYPE_MAP, APP_SCORING_STRATEGY_MAP } from "@/constant/appEnum";
import {
  addQuestionUsingPost,
  editQuestionUsingPost,
  listQuestionVoByPageUsingPost,
} from "@/axios/api/questionController";
import AiGenerateQuestionDrawer from "@/views/add/components/AiGenerateQuestionDrawer.vue";
// import PictureUploader from "@/components/PictureUploader.vue";

interface Props {
  appId: number;
}

const props = withDefaults(defineProps<Props>(), {
  appId: () => 0,
});
// 题目内容结构
const questionContent = ref<API.QuestionContentDTO[]>([]);
/**
 * 添加题目
 * @param index
 */
const addQuestion = (index: number) => {
  questionContent.value.splice(index, 0, {
    title: "",
    options: [],
  });
};
/**
 * 删除题目
 * @param index
 */
const deleteQuestion = (index: number) => {
  questionContent.value.splice(index, 1);
};
/**
 * 添加题目选项
 * @param question
 * @param index
 */
const addQuestionOption = (question: API.QuestionContentDTO, index: number) => {
  if (!question.options) question.options = [];
  question.options.splice(index, 0, {
    key: "",
    value: "",
  });
};
/**
 * 删除题目选项
 * @param question
 * @param index
 */
const deleteQuestionOption = (
  question: API.QuestionContentDTO,
  index: number
) => {
  if (!question.options) question.options = [];
  question.options.splice(index, 1);
};
const oldQuestion = ref<API.QuestionVO>();
/**
 * 加载数据
 */
const loadData = async () => {
  if (!props.appId) return;
  const res = await listQuestionVoByPageUsingPost({
    appId: props.appId,
    current: 1,
    pageSize: 1,
    sortField: "createTime",
    sortOrder: "descend",
  });
  if (res.data.code === 0 && res.data.data?.records) {
    oldQuestion.value = res.data.data?.records[0];
    if (oldQuestion.value) {
      questionContent.value = oldQuestion.value.questionContent ?? [];
    }
  } else {
    message.error("获取数据失败：" + res.data.message);
  }
};
watchEffect(() => {
  loadData();
});
const loginUserStore = useLoginUserStore();
const router = useRouter();
const handleSubmit = async () => {
  if (!props.appId || !questionContent.value) return;
  let res: any;
  if (oldQuestion.value?.id) {
    res = await editQuestionUsingPost({
      id: oldQuestion.value.id,
      questionContent: questionContent.value,
    });
  } else {
    res = await addQuestionUsingPost({
      appId: props.appId,
      questionContent: questionContent.value,
    });
  }
  if (res.data.code === 0) {
    message.success("操作成功，即将跳转到应用详情页面...");
    setTimeout(() => {
      router.push({
        path: `/app/detail/${props.appId}`,
      });
    }, 3000);
  } else {
    message.error("操作失败" + res.data.message);
  }
};
/**
 * AI生成题目成功后执行
 */
const onAiGenerateSuccess = (result: API.QuestionContentDTO[]) => {
  questionContent.value = [...questionContent.value, ...result];
};
/**
 * AI生成题目成功后执行(SSE)
 */
 const onAiGenerateSuccessSSE = (result: API.QuestionContentDTO[]) => {
  // @ts-ignore
  questionContent.value = [...questionContent.value, result];
};
/**
 * SSE 开始生成
 * @param event 
 */
const onSSEOpen = (event: Event) => {
  message.success("开始生成题目");
}
/**
 * SSE 生成成功
 * @param event 
 */
const onSSEClose = (event: Event) => {
  message.success("生成题目成功");
}
</script>
