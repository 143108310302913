import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "userLoginPage" }
const _hoisted_2 = { style: {"display":"flex","justify-content":"space-between","width":"100%","align-items":"center"} }

import API from "@/axios/api";
import { userLoginUsingPost } from "@/axios/api/userController";
import { useLoginUserStore } from "@/store/userStore";
import { reactive } from "vue";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";

export default /*@__PURE__*/_defineComponent({
  __name: 'UserLoginPage',
  setup(__props) {

const form = reactive({
  userAccount: "",
  userPassword: "",
} as API.UserLoginRequest);
const loginUserStore = useLoginUserStore();
const router = useRouter();
const handleSubmit = async () => {
  console.log(form);
  const res = await userLoginUsingPost(form);
  if (res.data.code === 0) {
    await loginUserStore.fetchLoginUser();
    message.success("登录成功");
    router.push({
      path: "/",
      replace: true,
    });
  } else {
    message.error("登录失败" + res.data.message);
  }
};

return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_link = _resolveComponent("a-link")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("h2", { style: {"margin-bottom":"16px"} }, "用户登录", -1)),
    _createVNode(_component_a_form, {
      model: form,
      style: { width: '480px', margin: '0 auto' },
      "label-align": "left",
      "auto-label-width": "",
      onSubmit: handleSubmit
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, {
          field: "userAccount",
          label: "账号",
          "validate-trigger": "input",
          required: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              modelValue: form.userAccount,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.userAccount) = $event)),
              placeholder: "请输入你的账号"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          field: "userPassword",
          label: "密码",
          tooltip: "密码不小于 8 位",
          required: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input_password, {
              modelValue: form.userPassword,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.userPassword) = $event)),
              placeholder: "请输入你的密码"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_a_button, {
                "html-type": "submit",
                type: "primary",
                style: {"width":"120px"}
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("登录")
                ])),
                _: 1
              }),
              _createVNode(_component_a_link, {
                href: "/user/register",
                style: {"margin-left":"16px"}
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("新用户注册")
                ])),
                _: 1
              })
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"])
  ]))
}
}

})