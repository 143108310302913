<template>
  <div id="appStatisticPage">
    <h2>热门应用统计</h2>
    <v-chart :option="appAnswerCountOptions" style="height: 300px" />
    <h2>应用结果统计</h2>
    <div class="search_bar">
      <a-input-search
        :style="{ width: '320px' }"
        placeholder="请输入AppId"
        button-text="搜索"
        size="large"
        search-button
        @search="(value) => loadAppAnswerResultCountData(value)"
      />
    </div>
    <div style="margin-bottom: 16px"></div>
    <v-chart :option="appAnswerResultCountOptions" style="height: 300px" />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watchEffect } from "vue";
import message from "@arco-design/web-vue/es/message";
import "echarts";
import VChart from "vue-echarts";
import { REVIEW_STATUS_ENUM } from "@/constant/app";
import { listAppVoByPageUsingPost } from "@/axios/api/appController";
import {
  doAppAnswerCountUsingGet,
  doAppAnswerResultCountUsingGet,
} from "@/axios/api/statisticController";

const appAnswerCountList = ref<API.AppAnswerCountDTO[]>([]);
const appAnswerResultCountList = ref<API.AppAnswerResultCountDTO[]>([]);
const appAnswerCountOptions = computed(() => {
  return {
    xAxis: {
      type: "category",
      data: appAnswerCountList.value.map((item) => item.appId),
      name: "应用 ID",
    },
    yAxis: {
      type: "value",
      name: "回答次数",
    },
    series: [
      {
        data: appAnswerCountList.value.map((item) => item.answerCount),
        type: "bar",
      },
    ],
  };
});
const appAnswerResultCountOptions = computed(() => {
  return {
    legend: {
      orient: "vertical",
      left: "left",
    },
    series: [
      {
        name: "应用答案结果分布",
        type: "pie",
        radius: "50%",
        data: appAnswerResultCountList.value.map((item) => {
          return { value: item.resultCount, name: item.resultName };
        }),
        // [ { value: 1048, name: "Search Engine" },]
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
});

const loadAppAnswerCountData = async () => {
  const res = await doAppAnswerCountUsingGet();
  if (res.data.code === 0) {
    appAnswerCountList.value = res.data.data || [];
  } else {
    message.error("获取数据失败：" + res.data.message);
  }
};
const loadAppAnswerResultCountData = async (appId: string) => {
  if (!appId) return;
  const res = await doAppAnswerResultCountUsingGet({
    appId: appId as any,
  });
  if (res.data.code === 0) {
    appAnswerResultCountList.value = res.data.data || [];
  } else {
    message.error("获取数据失败：" + res.data.message);
  }
};
/**
 * 参数改变时数据重新加载
 */
watchEffect(() => {
  loadAppAnswerCountData();
});
watchEffect(() => {
  loadAppAnswerResultCountData("");
});
</script>

<style scoped></style>
